<template>
	<div style="padding: 1vw;">
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title1')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content1')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="designDocuments" placeholder="" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title2')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content2')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice-image :dataIndex="0" :dataList="dataList1[1]" @select="selectBoxClick"></single-choice-image>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title3')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content3')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="boardNumberPliesIndex" :dataList="dataList1[2]" @select="numberBoardLayersSelect"></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title4')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<change-single-choice :dataIndex="TGindex" :dataList="dataList1[3]" @dataListDataInfo="selectTGClick" @select="selectTGClick"></change-single-choice>
				</div>
				<div style="display: flex;">
					<div style="font-size: 12px;color: red;">*</div>
					<div style="color: #888;font-size: 12px;">{{$t('valuation.pcb.boardElementTwo.text1')}}</div>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<!-- <div class="be_mandatory">*</div> -->
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title5')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content5')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<change-single-choice :dataIndex="boardThicknessIndex" :dataList="dataList1[4]" @dataListDataInfo="selectThicknessClick" @select="selectThicknessClick"></change-single-choice>
				</div>
				<div style="display: flex;">
					<div style="font-size: 12px;color: red;">*</div>
					<div style="color: #888;font-size: 12px;">{{$t('valuation.pcb.boardElementTwo.text2')}}</div>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<!-- <div class="be_mandatory">*</div> -->
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title6')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content6')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<change-single-choice :dataIndex="copperThicknessIndex" :dataList="dataList1[5]" @dataListDataInfo="selectCopperThicknessClick" @select="selectCopperThicknessClick"></change-single-choice>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<!-- <div class="be_mandatory">*</div> -->
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title7')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice :dataIndex="minimumLineWidth" :dataList="dataList1[6]" @select="selectMinimumLineWidhClick"></single-choice>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<!-- <div class="be_mandatory">*</div> -->
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title8')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice :dataIndex="minimumThroughHole" :dataList="dataList1[7]" @select="selectMinimumThroughHoleClick"></single-choice>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title9')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content7')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice-color :dataIndex="resistanceWeldingColor" :dataList="dataList1[8]" @select="selectResistanceWeldingColorClick"></single-choice-color>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title10')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content8')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice-color :dataIndex="characterColor" :dataList="dataList1[9]" @select="selectCharacterColorClick"></single-choice-color>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title11')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content9')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice-popup :dataIndex="solderPadSprayCoating" :dataList="dataList1[10]" @select="selectSolderPadSprayCoatingClick" @dialogSelect="selectDialogSelectClick"></single-choice-popup>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title12')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content10')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice :dataIndex="resistanceWeldingCoverage" :dataList="dataList1[11]" @select="selectResistanceWeldingCoverageClick"></single-choice>
				</div>
				<div style="display: flex;">
					<div style="color: red;">{{$t('valuation.pcb.boardElementTwo.text3')}}</div>
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.pcb.boardElementTwo.title13')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.pcb.boardElementTwo.content11')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div v-for="(item,index) in dataList1[12]" :key="index">
					<div style="display: flex;align-items: center;padding-bottom: 1vw;">
						<input style="width: 10px;height: 10px;background-color: red;" type="radio" v-model="TestMethodRadio" :value="index"/>
						<label style="font-size: 12px;padding-left: 10px;">{{$i18n.locale=='cn' ? item.name : item.enname}}</label> 
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
	import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
	import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
	import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
	import {dataInfo} from '../dataHandling.js';
	import { calcPcbPriceReturn } from '../saveOrder.js'
	export default {
		components:{
			singleChoice,
			changeSingleChoice,
			singleChoiceImage,
			singleChoiceColor,
			singleChoicePopup
		},
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				TestMethodRadio:0,//测试方式下标
				dataList1:this.dataList,
				
				designDocuments:null,//设计文件数
				boardType:0,//板子类型
				boardNumberPliesIndex:0,//板子层数
				TGindex:1,//板材/TG选项位置
				boardThicknessIndex:0,//板子厚度下标
				BoardThickness:1,//板子厚度选项位置
				copperThicknessIndex:0,//铜箔厚度下标
				copperThickness:1,//铜箔厚度
				minimumLineWidth:0,//最小线宽下标
				minimumThroughHole:0,//最小过孔下标
				resistanceWeldingColor:0,//焊接颜色下标
				characterColor:0,//字符颜色下标
				solderPadSprayCoating:0,//焊盘喷镀下标
				resistanceWeldingCoverage:0,//焊接盖盘下标
			}
		},
		
		created() {
			this.designDocuments = this.$store.state.selectForm.designDocuments;
			this.boardType = this.$store.state.selectForm.boardType - 1;
			this.boardNumberPliesIndex = this.$store.state.selectForm.boardNumberPliesIndex - 1;
			this.TGindex = this.$store.state.selectForm.TGindex;
			this.boardThicknessIndex = this.$store.state.selectForm.boardThicknessIndex;
			this.copperThicknessIndex = this.$store.state.selectForm.copperThicknessIndex;
			this.minimumLineWidth = this.$store.state.selectForm.minimumLineWidth - 1;
			this.minimumThroughHole = this.$store.state.selectForm.minimumThroughHole - 1;
			this.resistanceWeldingColor = this.$store.state.selectForm.resistanceWeldingColor - 1;
			this.characterColor = this.$store.state.selectForm.characterColor - 1;
			this.solderPadSprayCoating = this.$store.state.selectForm.solderPadSprayCoating - 1;
			this.resistanceWeldingCoverage = this.$store.state.selectForm.resistanceWeldingCoverage - 1;
			this.TestMethodRadio = this.$store.state.selectForm.TestMethodRadio - 1;
			
			this.dataList1 = dataInfo(this.boardNumberPliesIndex,this.dataList)
		},
		
		mounted() {
			
		},
		
		watch:{
			designDocuments(val){
				this.$store.dispatch("setDesignDocuments", {
				    designDocuments: Number(val),
				});
				calcPcbPriceReturn()
			},
			
			TestMethodRadio(val){
				this.$store.dispatch("setTestMethodRadio", {
				    TestMethodRadio: val + 1,
				});
				calcPcbPriceReturn()
			}
		},
		
		methods:{
			//--------------------单选/多选框选择------------------------------------
			selectBoxClick:function(index){
				this.boardType = index;
				this.$store.dispatch("setBoardType", {
				    boardType: index + 1,
				});
				calcPcbPriceReturn()
			},
			
			//--------------------层数单选框回调---------------------------------
			numberBoardLayersSelect:function(e){
				this.boardNumberPliesIndex=e;
				this.$store.dispatch("setBoardNumberPliesIndex", {
				    boardNumberPliesIndex: e + 1,
				});
				this.dataList1=dataInfo(e,this.dataList);
				// console.log(dataInfo(e))
				calcPcbPriceReturn();
			},
			
			//------------------板材/TG单选回调------------------------------
			selectTGClick:function(e){
				this.$store.dispatch("setTGindex",{
					TGindex:e
				})
				for(var i = 0; i < this.dataList1[3].length; i ++){
					if(this.dataList1[3][i].isdele==false){
						if(this.dataList1[3][i].name == this.dataList1[3][e].name){
							this.$store.dispatch("setTGsub",{
								TGsub:i+1
							})
						}
					}
				}
				calcPcbPriceReturn()
			},
			
			//------------------板子厚度单选回调-------------------------------
			selectThicknessClick:function(e){
				this.$store.dispatch("setBoardThicknessIndex",{
					boardThicknessIndex:e
				})
				for(var i = 0; i < this.dataList1[4].length; i ++){
					if(this.dataList1[4][i].isdele==false){
						if(this.dataList1[4][i].name == this.dataList1[4][e].name){
							this.$store.dispatch("setBoardThickness",{
								BoardThickness:i+1
							})
						}
					}
				}
				calcPcbPriceReturn()
			},
			
			//----------------铜箔厚度单选回调--------------------------------
			selectCopperThicknessClick:function(e){
				this.$store.dispatch("setCopperThicknessIndex",{
					copperThicknessIndex:e
				})
				for(var i = 0; i < this.dataList1[5].length; i ++){
					if(this.dataList1[5][i].isdele==false){
						if(this.dataList1[5][i].name == this.dataList1[5][e].name){
							this.$store.dispatch("setCopperThickness",{
								copperThickness:i+1
							})
						}
					}
				}
				calcPcbPriceReturn()
			},
			
			//----------------最小线宽/线距-----------------------------------------
			selectMinimumLineWidhClick:function(e){
				this.minimumLineWidth = e;
				this.$store.dispatch("setMinimumLineWidth", {
				    minimumLineWidth: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//----------------最小过孔单选回调----------------------------------
			selectMinimumThroughHoleClick:function(e){
				this.minimumThroughHole = e;
				this.$store.dispatch("setMinimumThroughHole", {
				    minimumThroughHole: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//-----------------焊接颜色-----------------------------------
			selectResistanceWeldingColorClick:function(e){
				this.resistanceWeldingColor = e;
				this.$store.dispatch("setResistanceWeldingColor", {
				    resistanceWeldingColor: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//----------------字符颜色单选回调----------------------------
			selectCharacterColorClick:function(e){
				this.characterColor = e;
				this.$store.dispatch("setCharacterColor", {
				    characterColor: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//-----------------焊盘喷镀-------------------------------
			selectSolderPadSprayCoatingClick:function(e){
				this.solderPadSprayCoating = e;
				this.$store.dispatch("setSolderPadSprayCoating", {
				    solderPadSprayCoating: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//----------------焊盘喷镀popup------------------------
			selectDialogSelectClick:function(e){
				this.$store.dispatch("setGoldthick", {
				    goldthick: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//----------------焊接盖盘---------------------------------
			selectResistanceWeldingCoverageClick:function(e){
				this.resistanceWeldingCoverage = e;
				this.$store.dispatch("setResistanceWeldingCoverage", {
				    resistanceWeldingCoverage: e + 1,
				});
				calcPcbPriceReturn()
			}
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;
		
	}
	
	@media only screen and (min-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 100px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 12px;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1{
			width: 60px;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:30%;
			
			.be_mandatory{
				font-size: 12px;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 12px;
			}
		}
		
		.input1{
			width: 60px;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
</style>