<template>
	<div style="padding: 10px;">
		<el-checkbox-group v-model="checkList" @change="checkboxChange">
		    <el-checkbox v-for="(item,index) in dataList[14]" :key="index" :label="index+1">{{$i18n.locale=='cn' ? item.name : item.enname}}</el-checkbox>
		</el-checkbox-group>
	</div>
</template>

<script>
	import { calcPcbPriceReturn } from '../saveOrder.js'
	export default {
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				checkList: [],
				checkListData:[],
			}
		},
		
		created() {
			this.checkList = this.$store.state.selectForm.checkList;
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			checkboxChange:function(e){
				// console.log("change",e)
				// console.log("change2",this.checkList)
				this.checkListData=[]
				// e.forEach(res=>{
				// 	this.checkListData.push(res)
				// })
				this.$store.dispatch("setCheckList", {
				    checkList: e,
				});
				var a = e.toString();
				// console.log("11111",a)
				this.$store.dispatch("setCheckListData", {
				    checkListData: a,
				});
				// console.log(this.checkListData)
				calcPcbPriceReturn()
				
			}
		}
	}
</script>

<style lang="less" scoped>
	
</style>