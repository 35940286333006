<template>
	<div>
		<div v-if="windowWidth>=768">
			<div class="tabs" :style="'background-image:' + `url(${require('@/assets/img/PCB-quote@banner.jpg')})`">
				<div class="tabs_div">
					<div class="tabs_div_tabs" style="border-top-left-radius: 40px;" :style="tabsIndex==0 ? 'background:#fff;' : ''" @click="tabsClick(0)">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-PCB@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text1')}}</div>
					</div>
					<!-- <div class="tabs_div_tabs" @click="tabsClick(1)" :style="tabsIndex==1 ? 'background:#fff;' : ''">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-FPC@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text2')}}</div>
					</div> -->
					<div class="tabs_div_tabs" @click="tabsClick(2)" :style="tabsIndex==2 ? 'background:#fff;' : ''">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-SMT@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text3')}}</div>
					</div>
					<div class="tabs_div_tabs" @click="tabsClick(3)" :style="tabsIndex==3 ? 'background:#fff;' : ''">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-GW@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text4')}}</div>
					</div>
					<div class="tabs_div_tabs" style="border-top-right-radius: 40px;" :style="tabsIndex==4 ? 'background:#fff;' : ''" @click="tabsClick(4)">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-Bom@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text5')}}</div>
					</div>
				</div>
			</div>
			<div style="padding: 10px 200px;">
				<!-- <el-image style="width: 100%;" :src="require('@/assets/img/pricingMethod.png')" :fit="'cover'"></el-image> -->
			</div>
		</div>
		
		<div v-if="windowWidth<=767">
			<div class="tabs2" :style="'background-image:' + `url(${require('@/assets/img/PCB-quote@banner.jpg')})`">
				<div class="tabs_div">
					<div class="tabs_div_tabs" style="border-top-left-radius: 10px;" :style="tabsIndex==0 ? 'background:#fff;' : ''" @click="tabsClick(0)">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-PCB@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text1')}}</div>
					</div>
					<!-- <div class="tabs_div_tabs" @click="tabsClick(1)" :style="tabsIndex==1 ? 'background:#fff;' : ''">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-FPC@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text2')}}</div>
					</div> -->
					<div class="tabs_div_tabs" @click="tabsClick(2)" :style="tabsIndex==2 ? 'background:#fff;' : ''">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-SMT@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text3')}}</div>
					</div>
					<div class="tabs_div_tabs" @click="tabsClick(3)" :style="tabsIndex==3 ? 'background:#fff;' : ''">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-GW@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text4')}}</div>
					</div>
					<div class="tabs_div_tabs" style="border-top-right-radius: 10px;" :style="tabsIndex==4 ? 'background:#fff;' : ''" @click="tabsClick(4)">
						<el-image class="tabs_div_tabs_image" :src="require('@/assets/img/quote-Bom@ico.png')" :fit="'cover'"></el-image>
						<div class="tabs_div_tabs_text">{{$t('valuation.tabs.text5')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabsIndex:0,
			}
		},
		
		created() {
			this.tabsIndex = this.$store.state.Layout.topTabsIndex;
		},
		
		mounted() {
			
		},
		
		watch:{
			tabsIndex1(val){
				// console.log("tabs",val)
				this.tabsIndex=val
				this.tabsClick(val)
			}
		},
		
		computed:{
			tabsIndex1(){
				return this.$store.state.Layout.topTabsIndex;
			}
		},
		
		methods:{
			//------------------tabs点击------------------------
			tabsClick:function(e){
				this.tabsIndex = e;
				this.$emit('tabsClick',e)
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.tabs{
			width: 100%;
			height: 120px;
			background-size: cover;
			position: relative;
			
			.tabs_div{
				position: absolute;
				bottom: 0;
				padding: 0 200px;
				display: flex;
				
				.tabs_div_tabs{
					cursor: pointer;
					padding: 0.7vw 2.5vw;
					display: flex;
					align-items: center;
					background: #504f4f;
					
					.tabs_div_tabs_image{
						width: 2.4vw;
						height: 2vw;
					}
					
					.tabs_div_tabs_text{
						color: #888;
						font-size: 1vw;
						padding-left: 20px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.tabs{
			width: 100%;
			height: 120px;
			background-size: cover;
			position: relative;
			
			.tabs_div{
				position: absolute;
				bottom: 0;
				padding: 0 200px;
				display: flex;
				
				.tabs_div_tabs{
					cursor: pointer;
					padding: 0.7vw 2.5vw;
					display: flex;
					align-items: center;
					background: #504f4f;
					
					.tabs_div_tabs_image{
						width: 2.4vw;
						height: 2vw;
					}
					
					.tabs_div_tabs_text{
						color: #888;
						font-size: 1vw;
						padding-left: 20px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.tabs{
			width: 100%;
			height: 200px;
			background-size: cover;
			position: relative;
			
			.tabs_div{
				position: absolute;
				bottom: 0;
				padding: 0 140px;
				display: flex;
				
				.tabs_div_tabs{
					cursor: pointer;
					padding: 0.7vw 2.5vw;
					display: flex;
					align-items: center;
					background: #504f4f;
					
					.tabs_div_tabs_image{
						width: 2.4vw;
						height: 2vw;
					}
					
					.tabs_div_tabs_text{
						color: #888;
						font-size: 1vw;
						padding-left: 20px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.tabs{
			width: 100%;
			height: 200px;
			background-size: cover;
			position: relative;
			
			.tabs_div{
				position: absolute;
				bottom: 0;
				padding: 0 30px;
				display: flex;
				
				.tabs_div_tabs{
					cursor: pointer;
					padding: 0.7vw 2.5vw;
					display: flex;
					align-items: center;
					background: #504f4f;
					
					.tabs_div_tabs_image{
						width: 2.4vw;
						height: 2vw;
					}
					
					.tabs_div_tabs_text{
						color: #888;
						font-size: 1vw;
						padding-left: 20px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.tabs{
			width: 100%;
			height: 200px;
			background-size: cover;
			position: relative;
			
			.tabs_div{
				position: absolute;
				bottom: 0;
				padding: 0 30px;
				display: flex;
				
				.tabs_div_tabs{
					cursor: pointer;
					padding: 0.7vw 2.5vw;
					display: flex;
					align-items: center;
					background: #504f4f;
					
					.tabs_div_tabs_image{
						width: 2.4vw;
						height: 2vw;
					}
					
					.tabs_div_tabs_text{
						color: #888;
						font-size: 1vw;
						padding-left: 20px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.tabs2{
			width: 100%;
			height: 200px;
			background-size: cover;
			position: relative;
			
			.tabs_div{
				position: absolute;
				bottom: 0;
				padding: 0 30px;
				display: flex;
				
				.tabs_div_tabs{
					cursor: pointer;
					padding: 1.5vw 2.5vw;
					display: flex;
					align-items: center;
					background: #504f4f;
					
					.tabs_div_tabs_image{
						width: 2.4vw;
						height: 2vw;
					}
					
					.tabs_div_tabs_text{
						color: #888;
						font-size: 10px;
						padding-left: 5px;
					}
				}
			}
		}
	}
</style>