import askApi from '@/api/sell/ask'

export function changeText(item) {
	var test = JSON.stringify(item) // JSON.stringify()用于将JavaScript对象或值转换为JSON字符串
	var test2 = test.slice(1, test.length - 1)
	var test3 = []
	for (var i = 0; i < test2.length; i++) {
		test3.push(test2[i].replace('"', ''))
	}
	for (var j = 0; j < test3.length; j++) {
		if (test3[j] === '') {
			test3.splice(j, 1)
		}
	}
	var tempObj = test3.join('')
	// console.log(tempObj)
	return tempObj;
	
}

//计算毛利
export function grossProfitText(placeOrderList,r) {
	// console.log(r)
	var salePrice=null;
	var procPrice=null;
	var result=null;
	placeOrderList.pcbInfoList.forEach((item,index)=>{
		if(index==(r-1)){
			// console.log(index)
			item.proData.forEach((item2,index2)=>{
				//计算毛利
				// console.log("----------------------")
				salePrice = Number(item.actualprice)*Number(item.bomamount);
				// console.log("actualprice1",item.actualprice)
				// console.log("actualprice1",item.bomamount)
				// console.log("actualprice1",salePrice)
				
				procPrice = Number(item2.actualprice)*Number(item2.procqty);
				// console.log("actualprice2",item2.actualprice)
				// console.log("actualprice2",item2.procqty)
				// console.log("actualprice2",procPrice)
				
				salePrice = salePrice/(1+Number(placeOrderList.taxpoint))
				procPrice = procPrice/(1+Number(item2.invoce))
				result = salePrice - procPrice
				// console.log("actualprice3",placeOrderList.taxpoint)
				// console.log("actualprice3",item2.invoce)
				// console.log("actualprice3",result)
			})
		}
	})
	// console.log("result",result)
	
	return result;
}

//调整毛利计算毛利
export function grossProfitText2(procprice,procactualprice,bomamount) {
	// console.log(r)
	var salePrice=null;
	var procPrice=null;
	var result=null;
	//计算毛利
	// console.log("----------------------")
	salePrice = Number(procprice)*Number(bomamount);
	// console.log("actualprice1",item.actualprice)
	// console.log("actualprice1",item.bomamount)
	// console.log("actualprice1",salePrice)
	
	procPrice = Number(procactualprice)*Number(bomamount);
	// console.log("actualprice2",item2.actualprice)
	// console.log("actualprice2",item2.procqty)
	// console.log("actualprice2",procPrice)
	result = salePrice - procPrice
	// console.log("actualprice3",placeOrderList.taxpoint)
	// console.log("actualprice3",item2.invoce)
	// console.log("actualprice3",result)
	// console.log("result",result)
	
	return result;
}

//计算毛利api
export function grossProfitTextApi(placeOrderList,r,ivprice,actualprice) {
	return new Promise((resolve, reject) => {
		// console.log(r)
		var salePrice=null;
		var procPrice=null;
		var result=null;
		placeOrderList.pcbInfoList.forEach((item,index)=>{
			if(index==r){
				//计算毛利
				askApi.getCalProfit({
					pcbinfo:{
						id:item.id,
						orderno:item.orderno,
						ivprice:ivprice,
						actualprice:actualprice,
						bomamount:item.bomamount
					}
				}).then(res=>{
					resolve(res.data)
				})
			}
		})
	})
}

//计算返点
export function calculateRebate(ivprice,actualprice){
	let cost = ivprice - actualprice;
	return cost;
}
