<template>
	<div style="padding: 1vw;">
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title1')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.FPC.flexibleBoardTwo.content1')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="FPCnumber" :placeholder="$t('valuation.FPC.flexibleBoardTwo.input1')" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title2')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.FPC.flexibleBoardTwo.content2')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="FPClayers" :dataList="dataList1.NumberFPClayers" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title3')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCmaterialQuality" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title4')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.FPC.flexibleBoardTwo.content3')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCBoardThickness" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title5')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.FPC.flexibleBoardTwo.content4')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCCopperThickness" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title6')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice-color :dataIndex="materialQuality" :dataList="dataList1.FPCCoveringFilm" @select=""></single-choice-color>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title7')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.FPC.flexibleBoardTwo.content5')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice-color :dataIndex="materialQuality" :dataList="dataList1.FPCCharacterColor" @select=""></single-choice-color>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title8')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice-popup :dataIndex="materialQuality" :dataList="dataList1.FPCspraying" @select=""></single-choice-popup>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title9')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCGoldDepositThickness" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title10')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCreinforce" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title11')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCAdhesiveBacking" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title12')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCElectromagneticFilm" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title13')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCMinimumLineWidth" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title14')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="materialQuality" :dataList="dataList1.FPCMinimumThroughHole" @select=""></single-choice>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.FPC.flexibleBoardTwo.title15')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.FPC.flexibleBoardTwo.title16')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div v-for="(item,index) in dataList1.text" :key="index">
					<div style="display: flex;align-items: center;padding-bottom: 1vw;">
						<input style="width: 1vw;height: 1vw;background-color: red;" type="radio" v-model="TestMethodRadio" :value="index"/>
						<label style="font-size: 0.5vw;padding-left: 0.5vw;">{{item.name}}</label> 
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
	import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
	import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
	import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
	export default {
		components:{
			singleChoice,
			changeSingleChoice,
			singleChoiceImage,
			singleChoiceColor,
			singleChoicePopup
		},
		props:{
			dataList: {
				type: Object,
				default: () => {}
			},
		},
		data() {
			return {
				FPCnumber:'',//FPC数量
				FPClayers:0,//FPC层数
				dataList1:this.dataList,
				materialQuality:0,//材质
				TestMethodRadio:0,//测试方式下标
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;
		
	}
	
	.el-icon-question {
		color: #888;
	}
	
	.el-icon-question:hover {
		color: red;
	}
	
	.be_title{
		display: flex;
		// align-items: center;
		width:15%;
		
		.be_mandatory{
			font-size: 1vw;
			color: red;
		}
		
		.be_text{
			color: #888;
			font-size: 0.8vw;
		}
	}
	
	.input1{
		width: 5vw;
		height: 2vw;
		line-height: 2vw;
		text-align: center;
		border: 1px solid #d6d6d6;
		background: #fff;
	}
	
	.x{
		display: inline-block;
		font-size: 0.8vw;
		border: 1px solid #d6d6d6;
		text-align: center;
		background: #efefef;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
	
	.input2{
		width: 5vw;
		height: 2vw;
		line-height: 2vw;
		text-align: center;
		border: 1px solid #d6d6d6;
		background: #fff;
	}
	
	.cm{
		display: inline-block;
		font-size: 0.8vw;
		border: 1px solid #d6d6d6;
		text-align: center;
		background: #efefef;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
</style>