import { render, staticRenderFns } from "./orderInformation.vue?vue&type=template&id=0043b9c8&scoped=true&"
import script from "./orderInformation.vue?vue&type=script&lang=js&"
export * from "./orderInformation.vue?vue&type=script&lang=js&"
import style0 from "./orderInformation.vue?vue&type=style&index=0&id=0043b9c8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0043b9c8",
  null
  
)

export default component.exports