<template>
	<div style="padding: 1vw;padding-bottom: 20px;">
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.order.title1')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice :dataIndex="invoiceType" :dataList="dataList[15]"
						@select="selectInvoiceTypeClick"></single-choice>
				</div>
			</div>
		</div>

		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.order.title2')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<single-choice :dataIndex="invoicingMethod" :dataList="dataList[16]"
						@select="selectInvoicingMethodClick"></single-choice>
				</div>
			</div>
		</div>

		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.order.title3')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="contractTitle" placeholder="" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>

		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.order.title4')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input2" type="text" v-model="placingOrder" :placeholder="$t('valuation.order.input1')" />
					<input class="input3" type="text" v-model="placingOrderPhone" :placeholder="$t('valuation.order.input2')" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>

		<!-- <div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.order.title5')}}：</div>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input2" type="text" v-model="technicians" :placeholder="$t('valuation.order.input1')" />
					<input class="input3" type="text" v-model="techniciansPhone" :placeholder="$t('valuation.order.input2')" />
				</div>
			</div>
		</div> -->

		<div style="display: flex;padding-top: 20px;padding-bottom: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.order.title6')}}：</div>
			</div>
			<div style="width: 85%;position: relative;">
				<el-button type="primary" @click="addressOpen()">{{$t('valuation.order.button1')}}</el-button>
				<div v-if="shippingAddressList.length>0" style="padding-top: 20px;">
					<a-radio-group v-model="shippingAddressIndex">
						<a-radio :style="radioStyle" v-for="(item,index) in shippingAddressList" :key="index"
							:value="index">
							{{item.province + ' ' + item.city + ' ' + item.region + ' ' + item.address + ' ' + item.contactuser + ' ' + item.contacttel}}
							<el-button type="primary" size="mini" style="margin-left: 20px;" @click="modify(item)">{{$t('valuation.order.button2')}}</el-button>
						</a-radio>
					</a-radio-group>
				</div>
			</div>
		</div>
		
		<login-model :open="loginOpen" @ok="submitForm2" @close="close"></login-model>
		<address-model :open="open" :defaultForm="form" @close="cancel" @ok="submitForm"></address-model>
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
	import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
	import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
	import loginModel from '../loginModel.vue'
	import {
		calcPcbPriceReturn
	} from '../saveOrder.js'
	import addressModel from './components/addressModel.vue'
	import {
		clientLand
	} from '@/api/home/index.js'
	import {
		getMyAddList
	} from '@/api/userInfo/index'
	import Cookies from "js-cookie";
	export default {
		components: {
			singleChoice,
			singleChoiceImage,
			singleChoiceColor,
			singleChoicePopup,
			addressModel,
			loginModel
		},
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				invoiceType: 0, //发票类型下标
				invoicingMethod: 0, //发票方式下标
				contractTitle: '', //收据以及合同抬头

				placingOrder: '', //下单人
				placingOrderPhone: '', //下单人手机号

				technicians: '', //技术人姓名
				techniciansPhone: '', //技术人手机号

				shippingAddress: '', //收货地址
				shippingAddressIndex:0,//默认收货地址下标
				shippingAddressList: [], //收获地址列表
				
				loginOpen:false,
				open: false, //是否打开弹框

				radioStyle: {
					display: 'block',
					width: '100%',
					textOverflow: 'ellipsis',
					whiteSpace: 'normal',
				},

				form: {
					id: undefined,//id
					uid: undefined,//uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: false, //是否默认
				},
				userInfo:null,//用户个人信息
				// 地址接口传参
				queryParam: {
					pageNum: 1,
					pageSize: 50,
					uid: undefined,
					isdelete:0
				},
			}
		},

		created() {
			var userInfo = Cookies.get("userInfo2")
			if(userInfo){
				userInfo = JSON.parse(userInfo)
			}
			this.userInfo=userInfo
			if(this.userInfo!==undefined){
				this.invoiceType = this.$store.state.selectForm.invoiceType - 1;
				this.invoicingMethod = this.$store.state.selectForm.invoicingMethod - 1;
				this.contractTitle = this.$store.state.selectForm.contractTitle;
				this.placingOrder = this.$store.state.selectForm.placingOrder == '' ? userInfo.contactuser : this.$store.state.selectForm.placingOrder;
				this.placingOrderPhone = this.$store.state.selectForm.placingOrderPhone ==  '' ? userInfo.contacttel : this.$store.state.selectForm.placingOrderPhone;
				this.technicians = this.$store.state.selectForm.technicians;
				this.techniciansPhone = this.$store.state.selectForm.techniciansPhone;
				// this.shippingAddress = this.$store.state.selectForm.shippingAddress;
				this.queryParam.uid=userInfo.id
				// this.shippingAddressList = this.$store.state.selectForm.shippingAddressList;
				this.getMyAddList();
			}
		},

		mounted() {
			
		},

		watch: {
			contractTitle(val) {
				this.$store.dispatch("setContractTitle", {
					contractTitle: val,
				});
				calcPcbPriceReturn()
			},
			placingOrder(val) {
				this.$store.dispatch("setPlacingOrder", {
					placingOrder: val,
				});
				calcPcbPriceReturn()
			},
			placingOrderPhone(val) {
				this.$store.dispatch("setPlacingOrderPhone", {
					placingOrderPhone: val,
				});
				calcPcbPriceReturn()
			},
			technicians(val) {
				this.$store.dispatch("setTechnicians", {
					technicians: val,
				});
				calcPcbPriceReturn()
			},
			techniciansPhone(val) {
				this.$store.dispatch("setTechniciansPhone", {
					techniciansPhone: val,
				});
				calcPcbPriceReturn()
			},
			shippingAddressIndex(val){
				this.$store.dispatch("setShippingAddress", {
					shippingAddress: this.shippingAddressList[val].province + ' ' + this.shippingAddressList[val].city + ' ' + this.shippingAddressList[val].region + ' ' + this.shippingAddressList[val].address + ' ' + this.shippingAddressList[val].contactuser + ' ' + this.shippingAddressList[val].contacttel,
				});
			},
			// shippingAddress1(val) {
			// 	this.shippingAddress = val;
			// 	console.log(this.shippingAddress)
			// 	calcPcbPriceReturn()
			// },

			// shippingAddressList1(val) {
			// 	this.shippingAddressList = this.$store.state.selectForm.shippingAddressList;
			// },
		},

		computed: {
			// shippingAddress1() {
			// 	return this.$store.state.selectForm.shippingAddress
			// },
			// shippingAddressList1() {
			// 	return this.$store.state.selectForm.shippingAddressList
			// }
		},

		methods: {
			//------------------查询用户收货地址接口---------------------
			getMyAddList:function(){
				getMyAddList(this.queryParam).then(res=>{
					this.shippingAddressList=res.data.content
					if(res.data.content.length>0){
						this.$store.dispatch("setShippingAddress", {
							shippingAddress: res.data.content[this.shippingAddressIndex].province + ' ' + res.data.content[this.shippingAddressIndex].city + ' ' + res.data.content[this.shippingAddressIndex].region + ' ' + res.data.content[this.shippingAddressIndex].address + ' ' + res.data.content[this.shippingAddressIndex].contactuser + ' ' + res.data.content[this.shippingAddressIndex].contacttel,
						});
					}
					this.$store.dispatch("setShippingAddressList", {
						shippingAddressList: res.data.content
					});
					for(var i = 1 ; i <= res.data.content.length; i++){
						if(res.data.content[i]?.isdefault==1){
							this.shippingAddressIndex=i
						}
					}
					// console.log(this.shippingAddressList)
				})
			},
			
			//--------------------------发票类型单选回调-----------------------------
			selectInvoiceTypeClick: function(e) {
				this.invoiceType = e;
				this.$store.dispatch("setInvoiceType", {
					invoiceType: e + 1,
				});
			},

			//------------------------发票方式单选回调-----------------------------
			selectInvoicingMethodClick: function(e) {
				this.invoicingMethod = e;
				this.$store.dispatch("setInvoicingMethod", {
					invoicingMethod: e + 1,
				});
			},

			//---------------------打开选择地址弹框-----------------------------
			addressOpen: function() {
				if(this.userInfo==null){
					this.loginOpen=true
				}else{
					this.form.uid=this.userInfo.id;
					this.open = true;
				}
			},

			//---------------关闭弹框------------------------------
			cancel: function() {
				this.form = {
					id:undefined,//id
					uid:undefined,//uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: undefined, //是否默认
				}
				this.open = false;
			},

			//-----------------确认地址弹框回调----------------------
			submitForm: function() {
				this.form = {
					id:undefined,//id
					uid:undefined,//uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: undefined, //是否默认
				}
				this.open=false;
				this.getMyAddList()
			},
			
			//---------------修改地址弹框---------------------------
			modify:function(e){
				this.form.id=e.id;
				this.form.uid=e.uid;
				this.form.province=e.province;
				this.form.city=e.city;
				this.form.area=e.region;
				this.form.streetAdd=e.address;
				this.form.contacts=e.contactuser;
				this.form.phone=e.contacttel;
				this.form.type=e.isdefault;
				// console.log(this.form)
				this.open = true;
			},
			
			submitForm2:function(e){
				clientLand(e).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						Cookies.set("userInfo2", JSON.stringify(res.data), {
							expires: 30
						});
						Cookies.set("role2", res.role, {
							expires: 30
						});
						this.userInfo = Cookies.get("userInfo2")
						if(this.userInfo){
							this.userInfo = JSON.parse(this.userInfo)
						}
						this.getMyAddList();
						location.reload();
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			close:function(){
				this.loginOpen=false;
			}
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;

	}
	
	@media only screen and (min-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 18vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.input2 {
			width: 6vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
			margin-right: 1vw;
		}
		
		.input3 {
			width: 12vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2 {
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 18vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.input2 {
			width: 6vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
			margin-right: 1vw;
		}
		
		.input3 {
			width: 12vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2 {
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 18vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.input2 {
			width: 6vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
			margin-right: 1vw;
		}
		
		.input3 {
			width: 12vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2 {
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 18vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.input2 {
			width: 6vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
			margin-right: 1vw;
		}
		
		.input3 {
			width: 12vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2 {
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 200px;
			height: 23px;
			line-height: 23px;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.input2 {
			width: 60px;
			height: 23px;
			line-height: 23px;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
			margin-right: 1vw;
		}
		
		.input3 {
			width: 150px;
			height: 23px;
			line-height: 23px;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cm {
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 30%;
		
			.be_mandatory {
				font-size: 12px;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1 {
			width: 200px;
			height: 23px;
			line-height: 23px;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.input2 {
			width: 60px;
			height: 23px;
			line-height: 23px;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
			margin-right: 1vw;
		}
		
		.input3 {
			width: 150px;
			height: 23px;
			line-height: 23px;
			text-align: left;
			padding: 0 10px;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x {
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cm {
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}

</style>
