<template>
	<div style="padding: 1vw;">
		<!-- 元器件数量 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title1')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.SMT.specificationFilling.content1')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="componentsNumber" placeholder="" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>
		
		<!-- 电路板数量 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title2')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.SMT.specificationFilling.content2')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="circuitBoardsNumber" placeholder="" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>
		
		<!-- 工艺需求 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title3')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.SMT.specificationFilling.content3')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="processRequirements" :dataList="dataList1[18]" @select="processRequirementsSelect"></single-choice>
			</div>
		</div>
		
		<!-- 是否开钢网 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title4')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.SMT.specificationFilling.content4')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="steelMeshTypeIndex" :dataList="dataList1[19]" @select="steelMeshTypeSelect"></single-choice>
			</div>
		</div>
		
		<!-- 代购元件 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title5')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.SMT.specificationFilling.content5')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="purchaseBehalfIndex" :dataList="dataList1[20]" @select="purchaseOnBehalfSelect"></single-choice>
			</div>
		</div>
		
		<!-- 是否喷三防漆 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title6')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="conformalCoatingIndex" :dataList="dataList1[21]" @select="conformalCoatingSelect"></single-choice>
			</div>
		</div>
		
		<!-- 元器件是否烘培 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title7')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="componentBakingIndex" :dataList="dataList1[22]" @select="componentBakingSelect"></single-choice>
			</div>
		</div>
		
		<!-- 元器件烘焙时长 -->
		<div style="display: flex;padding-top: 20px;" v-if="componentBakingIndex==0">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title9')}}：</div>
			</div>
			<div style="width: 85%;">
				<single-choice :dataIndex="bakingTimeIndex" :dataList="dataList1[23]" @select="bakingTimeSelect"></single-choice>
			</div>
		</div>
		
		<!-- 其他需求 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.SMT.specificationFilling.title8')}}：</div>
			</div>
			<div style="width: 85%;">
				<el-input
				  type="textarea"
				  :rows="2"
				  placeholder=""
				  v-model="smtDemand">
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
	import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
	import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
	import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
	import {dataInfo} from '../dataHandling.js';
	import { calcSmtReturn } from '../calcPcbComponentlnfoPrice.js'
	export default {
		components:{
			singleChoice,
			changeSingleChoice,
			singleChoiceImage,
			singleChoiceColor,
			singleChoicePopup
		},
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				designDocuments:null,
				componentsNumber:null,//元器件数量
				circuitBoardsNumber:null,//电路板数量
				smtDemand:null,//其他需求
				dataList1:this.dataList,
				processRequirements:0,//工艺需求
				steelMeshTypeIndex:0,//是否开钢网
				purchaseBehalfIndex:0,//代购元件
				conformalCoatingIndex:0,//是否喷三防漆
				componentBakingIndex:1,//元器件是否烘培
				bakingTimeIndex:0,//烘培时间
				boardNumberPliesIndex:0,//板子层数
				steelMesh:[{
					name:'是',
					enName:"Yes",
					type:true
				}],
				componentBaking:[{
					name:'是',
					enName:"Yes",
					type:true
				},
				{
					name:'否',
					enName:"Not",
					type:true
				}],
				bakingTime:[{
					name:'12小时',
					enName:"12 Hour",
					type:true
				},
				{
					name:'24小时',
					enName:"24 Hour",
					type:true
				},
				{
					name:'48小时',
					enName:"48 Hour",
					type:true
				}],
				textarea:null,
			}
		},
		
		created() {
			this.componentsNumber = this.$store.state.selectForm.componentsNumber
			this.circuitBoardsNumber = this.$store.state.selectForm.circuitBoardsNumber
			this.processRequirements = this.$store.state.selectForm.processRequirements -1
			this.steelMeshTypeIndex = this.$store.state.selectForm.steelMeshTypeIndex -1
			this.purchaseBehalfIndex = this.$store.state.selectForm.purchaseBehalfIndex -1
			this.conformalCoatingIndex = this.$store.state.selectForm.conformalCoatingIndex -1
			this.componentBakingIndex = this.$store.state.selectForm.componentBakingIndex -1
			this.bakingTimeIndex = this.$store.state.selectForm.bakingTimeIndex -1
			this.smtDemand = this.$store.state.selectForm.smtDemand
		},
		
		mounted() {
			
		},
		
		watch:{
			componentsNumber(val){
				// console.log(val)
				this.$nextTick(()=>{
					this.$store.dispatch("setComponentsNumber", {
					    componentsNumber: val,
					});
					calcSmtReturn()
				})
			},
			circuitBoardsNumber(val){
				this.$nextTick(()=>{
					this.$store.dispatch("setCircuitBoardsNumber", {
					    circuitBoardsNumber: val,
					});
					calcSmtReturn()
				})
			},
			smtDemand(val){
				this.$nextTick(()=>{
					this.$store.dispatch("setSmtDemand", {
					    smtDemand: val,
					});
					calcSmtReturn()
				})
			}
		},
		
		computed:{
			
		},
		
		methods:{
			//-------------工艺需求----------------------
			processRequirementsSelect:function(index){
				this.processRequirements=index;
				this.$store.dispatch("setShippingMethod", {
				    processRequirements: index+1,
				});
				calcSmtReturn()
			},
			
			//------------是否开钢网回调-------------------
			steelMeshTypeSelect:function(index){
				this.steelMeshTypeIndex=index;
				this.$store.dispatch("setSteelMeshTypeIndex", {
				    steelMeshTypeIndex: index+1,
				});
				calcSmtReturn()
			},
			
			//--------------代购元件回调--------------------
			purchaseOnBehalfSelect:function(index){
				this.purchaseBehalfIndex = index;
				this.$store.dispatch("setPurchaseBehalfIndex", {
				    purchaseBehalfIndex: index+1,
				});
				calcSmtReturn()
			},
			
			//---------------是否喷三防漆回调--------------------
			conformalCoatingSelect:function(index){
				this.conformalCoatingIndex = index;
				this.$store.dispatch("setConformalCoatingIndex", {
				    conformalCoatingIndex: index+1,
				});
				calcSmtReturn()
			},
			
			//---------------元器件是否烘焙------------------------
			componentBakingSelect:function(index){
				this.componentBakingIndex = index
				this.$store.dispatch("setComponentBakingIndex", {
				    componentBakingIndex: index+1,
				});
				calcSmtReturn()
			},
			
			//---------------元器件是否烘焙------------------------
			bakingTimeSelect:function(index){
				this.bakingTimeIndex = index
				this.$store.dispatch("setBakingTimeIndex", {
				    bakingTimeIndex: index+1,
				});
				calcSmtReturn()
			}
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;
		
	}
	
	@media only screen and (min-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 10vw;
			line-height: 23px;
			height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 10vw;
			line-height: 23px;
			height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
			
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:30%;
			
			.be_mandatory{
				font-size: 12px;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1{
			width: 10vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 23px;
			height: 23px;
		}
		
		.x{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 10vw;
			line-height: 23px;
			height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
</style>