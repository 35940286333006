<template>
	<div>
		<div class="SMTpricing" v-if="windowWidth>=1200">
			<div class="sp_left">
				<div class="sp_le_div">
					<div class="sp_le_div_title">{{$t('valuation.SMT.title1')}}</div>
					<div class="sp_le_div_text1">{{$t('valuation.SMT.text1')}}</div>
				</div>
				<specification-filling :dataList="dataList"></specification-filling>
				<div class="sp_le_div">
					<div class="sp_le_div_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="sp_le_div_text2">{{$t('valuation.pcb.text5')}}</div>
				</div>
				<order-information :dataList="dataList"></order-information>
			</div>
		
			<div style="width: 30%;">
				<div class="smtQuotation">
					<div class="sq_title1">SMT报价和交期</div>
					<div class="sq_title2">SMT价格</div>
					<!-- <div class="sq_lableTitle">
						<span class="sq_lt_text1">交期</span>
						<span class="sq_lt_text2">价格</span>
					</div>
					<div class="sq_lableContent">
						<div @click="deliveryDate(1)">
							<div class="sq_lc_radioText" :style="value == 1  ? 'background: #fff;' : ''">
								<div style="width: 70%;">
									<a-radio-group v-model="value">
									    <a-radio :style="radioStyle" :value="1">
									      正常2-3天
									    </a-radio>
									</a-radio-group>
								</div>
								<div style="width: 30%;text-align: center;">￥410</div>
							</div>
						</div>
						
						<div @click="deliveryDate(2)">
							<div class="sq_lc_radioText" :style="value == 2  ? 'background: #fff;' : ''">
								<div style="width: 70%;">
									<a-radio-group v-model="value">
									    <a-radio :style="radioStyle" :value="2">
									      24小时加急
									    </a-radio>
									</a-radio-group>
								</div>
								<div style="width: 30%;text-align: center;">￥615</div>
							</div>
						</div>
						
						<div @click="deliveryDate(3)">
							<div class="sq_lc_radioText" :style="value == 3  ? 'background: #fff;' : ''">
								<div style="width: 70%;">
									<a-radio-group v-model="value">
									    <a-radio :style="radioStyle" :value="3">
									      12小时加急
									    </a-radio>
									</a-radio-group>
								</div>
								<div style="width: 30%;text-align: center;">￥615</div>
							</div>
						</div>
					</div> -->
			
					<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;display: flex;line-height: 2vw;">
						{{$t('valuation.quotation.text13')}}:
						<div style="font-size: 2vw;color: red;font-weight: bold;padding-left: 0.5vw;">
							￥{{smtfee || "0.00"}}</div>
					</div>
					<div style="position: relative;padding-top:20px;text-align: right;z-index: 1;">
						<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
							@click.native.prevent="dataInfo">
							<span v-if="!loading">{{$t('valuation.quotation.button')}}</span>
							<span v-else>{{$t('valuation.quotation.button')}}...</span>
						</el-button>
					</div>
					<!-- <div style="position: relative;padding-top:20px;text-align: right;z-index: 1;">
						<div style="padding: 10px 40px;cursor: pointer;display: inline-block;font-size: 16px;border: 1px solid #d1102d;background: #d1102d;color: #fff;box-shadow: 0 5px 7px rgb(255 104 34 / 28%);border-radius: 100px;"
							@click="dataInfo">{{$t('valuation.quotation.button')}}</div>
					</div> -->
				</div>
			</div>
		</div>
		
		<div class="SMTpricing2" v-if="windowWidth<=1199">
			<div class="sp_left">
				<div class="sp_le_div">
					<div class="sp_le_div_title">{{$t('valuation.SMT.title1')}}</div>
					<div class="sp_le_div_text1">{{$t('valuation.SMT.text1')}}</div>
				</div>
				<specification-filling :dataList="dataList"></specification-filling>
				<div class="sp_le_div">
					<div class="sp_le_div_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="sp_le_div_text2">{{$t('valuation.pcb.text5')}}</div>
				</div>
				<order-information :dataList="dataList"></order-information>
			</div>
			
			<div style="background: #fff;box-shadow: 0 15px 30px  rgba(0, 0, 0, .4);padding: 0 20px;position: fixed;bottom: 0;width: 100%;left: 0;display: flex;align-items: center;height: 60px;">
				<div style="padding-top: 10px;font-size: 12px;padding-bottom:10px;display: flex;line-height: 2vw;">
					{{$t('valuation.quotation.text13')}}:
					<div style="font-size: 2vw;color: red;font-weight: bold;padding-left: 10px;">
						￥{{smtfee || "0.00"}}</div>
						
					<!-- <div style="padding-left: 20px;color: #409EFF;" @click="drawerClick">{{$i18n.locale=="en" ? 'Quotation Details' : '报价明细'}}</div> -->
				</div>
				
				<div style="position: absolute;right: 0;background: #d1102d;padding: 0 40px;bottom: 0;color: #fff;height: 60px;display: flex;align-items: center;" @click="dataInfo">{{$t('valuation.quotation.button')}}</div>
			</div>
		</div>
		
		<login-model :open="loginOpen" @ok="submitForm" @close="close"></login-model>
	</div>
</template>

<script>
	import {
		dataInJs
	} from './data.js';
	import specificationFilling from './SMTpricing/specificationFilling.vue'
	import loginModel from './loginModel.vue'
	import {
		calcPcbPriceReturn
	} from './saveOrder.js'
	import {
		saveOrderReturn
	} from './submit.js'
	import {
		Notification,
		MessageBox,
		Message,
		Loading
	} from 'element-ui'
	import {
		saveOrder,
		savePcb,
		clientLand
	} from '../../../api/home/index.js'
	import {
		getMyAddList
	} from '@/api/userInfo/index'
	import orderInformation from './selectForm/orderInformation.vue'
	import Cookies from "js-cookie";
	export default {
		components: {
			specificationFilling,
			orderInformation,
			loginModel
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				dataList: null, //list数据
				shippingMethod: 0, //出货方式
				calcPcbPriceData: null, //获取计价数据
				userInfo: null,
				value: 1,
				radioStyle: {
				    display: 'block',
				    height: '30px',
				    lineHeight: '30px',
				},
				smtfee:null,//总价
				loginOpen:false,
				loading: false,
			}
		},

		created() {
			calcPcbPriceReturn()
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.calcPcbPriceData = this.$store.state.selectForm.calcPcbPriceData
			this.value = this.$store.state.selectForm.deliveryTime
			this.smtfee = this.$store.state.selectForm.smtfee
			
			this.dataList = this.$store.state.selectForm.dataInfoContent
		},

		mounted() {

		},

		watch: {
			"$store.state.selectForm.calcPcbPriceData"(val) {
				this.calcPcbPriceData = val
			},
			value(val){
				// console.log(val)
				this.$nextTick(()=>{
					this.$store.dispatch("setDeliveryTime", {
					    deliveryTime: val,
					});
					calcSmtReturn()
				})
			},
			smtfee1(val){
				this.smtfee = val
			}
		},

		computed: {
			smtfee1(){
				return this.$store.state.selectForm.smtfee
			}
		},

		methods: {
			//---------------------交期时间选择--------------------------
			deliveryDate:function(e){
				this.value=e
				this.$store.dispatch("setDeliveryTime", {
				    deliveryTime: e,
				});
				calcSmtReturn()
			},
			
			dataInfo: function() {
				this.loading=true;
				if(this.userInfo!==undefined){
					let queryParam ={
						pageNum: 1,
						pageSize: 50,
						uid: this.userInfo.id,
						isdelete:0
					}
					getMyAddList(queryParam).then(res=>{
						if(res.data.content.length>0){
							saveOrderReturn(2,this.calcPcbPriceData)
							this.loading=false;
						}else{
							this.$message.error('请新增订单地址~')
							this.loading=false;
						}
					})
				}else{
					this.loginOpen=true;
				}
			},
			
			submitForm:function(e){
				clientLand(e).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						Cookies.set("userInfo2", JSON.stringify(res.data), {
							expires: 30
						});
						Cookies.set("role2", res.role, {
							expires: 30
						});
						this.userInfo = Cookies.get("userInfo2")
						if(this.userInfo){
							this.userInfo = JSON.parse(this.userInfo)
						}
						this.dataInfo();
						location.reload();
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			handleClose(done) {
			    this.drawer=false
			},
			
			close:function(){
				this.loginOpen=false;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.SMTpricing{
			display: flex;
			width: 100%;
			padding: 0 200px;
			
			.sp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.sp_le_div{
					padding: 1vw;
					align-items: center;
					
					.sp_le_div_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sp_le_div_text1{
						font-size: 0.8vw;
						color: red;
						padding-top: 10px;
					}
					
					.sp_le_div_text2{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
		}
		
		.smtQuotation{
			padding: 20px;
			border: 1px solid #e8eaeb;
			margin-left: 20px;
			
			.sq_title1{
				font-weight: bold;
				font-size: 1vw;
			}
			
			.sq_title2{
				font-size: 0.8vw;
			}
			
			.sq_lableTitle{
				color: #999;
				font-size: 12px;
				font-weight: bold;
				border: 1px solid #eee;
				padding: 3px 0;
				display: flex;
				width: 100%;
				
				.sq_lt_text1{
					width: 70%;
					padding-left: 44px;
				}
				
				.sq_lt_text2{
					width: 30%;
					text-align: center;
				}
			}
			
			.sq_lableContent{
				border-radius: 0px 0px 3px 3px;
				padding: 4px 4px 0;
				position: relative;
				background-color: #f6f6f6;
				color: #858d90;
				margin-bottom: 8px;
				cursor: pointer;
				border: 1px solid #f1f3f4;
				text-align: left;
				
				.sq_lc_radioText{
					height: 33px;
					line-height: 33px;
					padding: 0 5px;
					border-radius: 2px;
					box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
					font-size: 13px;
					margin-bottom: 5px;
					cursor: pointer;
					z-index: 1;
					color: #666;
					display: flex;
					width: 100%;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.SMTpricing{
			display: flex;
			width: 100%;
			padding: 0 200px;
			
			.sp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.sp_le_div{
					padding: 1vw;
					align-items: center;
					
					.sp_le_div_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sp_le_div_text1{
						font-size: 0.8vw;
						color: red;
						padding-top: 10px;
					}
					
					.sp_le_div_text2{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
		}
		
		.smtQuotation{
			padding: 20px;
			border: 1px solid #e8eaeb;
			margin-left: 20px;
			
			.sq_title1{
				font-weight: bold;
				font-size: 1vw;
			}
			
			.sq_title2{
				font-size: 0.8vw;
			}
			
			.sq_lableTitle{
				color: #999;
				font-size: 12px;
				font-weight: bold;
				border: 1px solid #eee;
				padding: 3px 0;
				display: flex;
				width: 100%;
				
				.sq_lt_text1{
					width: 70%;
					padding-left: 44px;
				}
				
				.sq_lt_text2{
					width: 30%;
					text-align: center;
				}
			}
			
			.sq_lableContent{
				border-radius: 0px 0px 3px 3px;
				padding: 4px 4px 0;
				position: relative;
				background-color: #f6f6f6;
				color: #858d90;
				margin-bottom: 8px;
				cursor: pointer;
				border: 1px solid #f1f3f4;
				text-align: left;
				
				.sq_lc_radioText{
					height: 33px;
					line-height: 33px;
					padding: 0 5px;
					border-radius: 2px;
					box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
					font-size: 13px;
					margin-bottom: 5px;
					cursor: pointer;
					z-index: 1;
					color: #666;
					display: flex;
					width: 100%;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.SMTpricing{
			display: flex;
			width: 100%;
			padding: 0 140px;
			
			.sp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.sp_le_div{
					padding: 1vw;
					align-items: center;
					
					.sp_le_div_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sp_le_div_text1{
						font-size: 0.8vw;
						color: red;
						padding-top: 10px;
					}
					
					.sp_le_div_text2{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
		}
		
		.smtQuotation{
			padding: 20px;
			border: 1px solid #e8eaeb;
			margin-left: 20px;
			
			.sq_title1{
				font-weight: bold;
				font-size: 1vw;
			}
			
			.sq_title2{
				font-size: 0.8vw;
			}
			
			.sq_lableTitle{
				color: #999;
				font-size: 12px;
				font-weight: bold;
				border: 1px solid #eee;
				padding: 3px 0;
				display: flex;
				width: 100%;
				
				.sq_lt_text1{
					width: 70%;
					padding-left: 44px;
				}
				
				.sq_lt_text2{
					width: 30%;
					text-align: center;
				}
			}
			
			.sq_lableContent{
				border-radius: 0px 0px 3px 3px;
				padding: 4px 4px 0;
				position: relative;
				background-color: #f6f6f6;
				color: #858d90;
				margin-bottom: 8px;
				cursor: pointer;
				border: 1px solid #f1f3f4;
				text-align: left;
				
				.sq_lc_radioText{
					height: 33px;
					line-height: 33px;
					padding: 0 5px;
					border-radius: 2px;
					box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
					font-size: 13px;
					margin-bottom: 5px;
					cursor: pointer;
					z-index: 1;
					color: #666;
					display: flex;
					width: 100%;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.SMTpricing{
			display: flex;
			width: 100%;
			padding: 0 30px;
			
			.sp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.sp_le_div{
					padding: 1vw;
					align-items: center;
					
					.sp_le_div_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sp_le_div_text1{
						font-size: 0.8vw;
						color: red;
						padding-top: 10px;
					}
					
					.sp_le_div_text2{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
		}
		
		.smtQuotation{
			padding: 20px;
			border: 1px solid #e8eaeb;
			margin-left: 20px;
			
			.sq_title1{
				font-weight: bold;
				font-size: 1vw;
			}
			
			.sq_title2{
				font-size: 0.8vw;
			}
			
			.sq_lableTitle{
				color: #999;
				font-size: 12px;
				font-weight: bold;
				border: 1px solid #eee;
				padding: 3px 0;
				display: flex;
				width: 100%;
				
				.sq_lt_text1{
					width: 70%;
					padding-left: 44px;
				}
				
				.sq_lt_text2{
					width: 30%;
					text-align: center;
				}
			}
			
			.sq_lableContent{
				border-radius: 0px 0px 3px 3px;
				padding: 4px 4px 0;
				position: relative;
				background-color: #f6f6f6;
				color: #858d90;
				margin-bottom: 8px;
				cursor: pointer;
				border: 1px solid #f1f3f4;
				text-align: left;
				
				.sq_lc_radioText{
					height: 33px;
					line-height: 33px;
					padding: 0 5px;
					border-radius: 2px;
					box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
					font-size: 13px;
					margin-bottom: 5px;
					cursor: pointer;
					z-index: 1;
					color: #666;
					display: flex;
					width: 100%;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.SMTpricing2{
			display: flex;
			width: 100%;
			padding: 0 30px 100px 30px;
			
			.sp_left{
				width: 100%;
				border: 1px solid #e8eaeb;
				
				.sp_le_div{
					padding: 1vw;
					align-items: center;
					
					.sp_le_div_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sp_le_div_text1{
						font-size: 0.8vw;
						color: red;
						padding-top: 10px;
					}
					
					.sp_le_div_text2{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
		}
		
	}
	
	@media only screen and (max-width:767px) {
		.SMTpricing2{
			display: flex;
			width: 100%;
			padding: 0 30px 100px 30px;
			
			.sp_left{
				width: 100%;
				border: 1px solid #e8eaeb;
				
				.sp_le_div{
					padding: 10px;
					align-items: center;
					
					.sp_le_div_title{
						font-size: 12px;
						font-weight: bold;
					}
					
					.sp_le_div_text1{
						font-size: 10px;
						color: red;
						padding-top: 10px;
					}
					
					.sp_le_div_text2{
						font-size: 10px;
						color: #888;
						padding-top: 5px;
					}
				}
			}
		}
		
	}
	
</style>
