import {
	saveOrder
} from '@/api/home/index.js'
import store from '@/store'
import router from '@/router'
import {
	Message
} from 'element-ui'
import Vue from 'vue'
import Cookies from "js-cookie";

export function saveOrderReturn(e, calcPcbPriceData) {
	var userInfo = Cookies.get("userInfo2")
	if(userInfo){
		userInfo = JSON.parse(userInfo)
	}
	// console.log("userInfo",store.state.selectForm.calcPcbPriceData)
	if (userInfo !== null) {
		if (store.state.selectForm.placingOrder == '' && store.state.selectForm.placingOrderPhone == '') {
			Vue.prototype.$message.error('请输入下单联系人');
		} else if (store.state.selectForm.shippingAddress == '') {
			Vue.prototype.$message.error('请输入收货地址');
		}else {
			if (e == 1 && store.state.selectForm.boardLength == null) {
				Vue.prototype.$message.error('暂未选择PCB必填属性');
			} else if (e == 1 && store.state.selectForm.boardLength == '') {
				Vue.prototype.$message.error('暂未选择PCB必填属性');
			} else if(e == 1 && store.state.selectForm.boardWidth == null) {
				Vue.prototype.$message.error('暂未选择PCB必填属性');
			} else if (e == 1 && store.state.selectForm.boardWidth == '') {
				Vue.prototype.$message.error('暂未选择PCB必填属性');
			} else if(e == 1 && store.state.selectForm.boardNumber == null) {
				Vue.prototype.$message.error('暂未选择PCB必填属性');
			} else if (e == 1 && store.state.selectForm.boardNumber == '') {
				Vue.prototype.$message.error('暂未选择PCB必填属性');
			} else if (e == 2 && store.state.selectForm.componentsNumber == null) {
				Vue.prototype.$message.error('暂未选择SMT必填属性');
			} else if (e == 2 && store.state.selectForm.componentsNumber == '') {
				Vue.prototype.$message.error('暂未选择SMT必填属性');
			} else if (e == 2 && store.state.selectForm.circuitBoardsNumber == null) {
				Vue.prototype.$message.error('暂未选择SMT必填属性');
			} else if (e == 2 && store.state.selectForm.circuitBoardsNumber == '') {
				Vue.prototype.$message.error('暂未选择SMT必填属性');
			} else if (e == 3 && store.state.selectForm.steelMeshNumber == null) {
				Vue.prototype.$message.error('暂未选择钢网必填属性');
			} else if (e == 3 && store.state.selectForm.steelMeshNumber == '') {
				Vue.prototype.$message.error('暂未选择钢网必填属性');
			} else if (e == 4 && store.state.selectForm.xlsxDataList == null) {
				Vue.prototype.$message.error('请先上传BOM配单文件');
			} else {
				if (e == 1) {
					if (calcPcbPriceData.orderno) { 
						let pcbInfo = [{...store.state.selectForm.pcbInfo}]
						let pcbOderInfo = {
							totalprice: calcPcbPriceData.orderno,
							pcbprice: calcPcbPriceData.orderno,
							engineerfee: calcPcbPriceData.engineerfee,
							sprayfee: calcPcbPriceData.sprayfee,
							panelfee: calcPcbPriceData.panelfee,
							testfee: calcPcbPriceData.testfee,
							taxationfee: calcPcbPriceData.taxationfee,
							urgentfee: calcPcbPriceData.urgentfee,
							specfee: calcPcbPriceData.specfee,
							discountfee: calcPcbPriceData.discountfee,
							otherprice: calcPcbPriceData.otherprice,
							invoicetype: store.state.selectForm.invoiceType,
							invoiceway: store.state.selectForm.invoicingMethod,
							billletter: store.state.selectForm.contractTitle,
							uid: userInfo.id,
							ordername: store.state.selectForm.placingOrder,
							orderphone: store.state.selectForm.placingOrderPhone,
							techname: store.state.selectForm.technicians,
							techtel: store.state.selectForm.techniciansPhone,
							address: store.state.selectForm.shippingAddress,
							orderattr: "1"
						}
						saveOrderAjax(e,pcbInfo,pcbOderInfo)
					}
				} else if (e == 2) {
					// console.log("222")
					if (store.state.selectForm.smtfee !== null) {
						let pcbInfo = [{...store.state.selectForm.smtOrderInfo}]
						let pcbOderInfo = {
							smtfee: store.state.selectForm.smtfee,
							invoicetype: store.state.selectForm.invoiceType,
							invoiceway: store.state.selectForm.invoicingMethod,
							billletter: store.state.selectForm.contractTitle,
							uid: userInfo.id,
							ordername: store.state.selectForm.placingOrder,
							orderphone: store.state.selectForm.placingOrderPhone,
							techname: store.state.selectForm.technicians,
							techtel: store.state.selectForm.techniciansPhone,
							address: store.state.selectForm.shippingAddress,
							orderattr: "2"
						}
						saveOrderAjax(e,pcbInfo,pcbOderInfo)
					}
				} else if (e == 3) {
					if (store.state.selectForm.stentfee !== null) {
						let pcbInfo = [{...store.state.selectForm.steelOrderInfo}]
						let pcbOderInfo = {
							stentfee: store.state.selectForm.stentfee,
							invoicetype: store.state.selectForm.invoiceType,
							invoiceway: store.state.selectForm.invoicingMethod,
							billletter: store.state.selectForm.contractTitle,
							uid: userInfo.id,
							ordername: store.state.selectForm.placingOrder,
							orderphone: store.state.selectForm.placingOrderPhone,
							techname: store.state.selectForm.technicians,
							techtel: store.state.selectForm.techniciansPhone,
							address: store.state.selectForm.shippingAddress,
							orderattr: "3"
						}
						saveOrderAjax(e,pcbInfo,pcbOderInfo)
					}
				}else if(e==4){
					if (store.state.selectForm.xlsxDataList !== null && store.state.selectForm.xlsxDataList.length !== 0) {
						if(store.state.selectForm.bomQuantityIndex==1 && !store.state.selectForm.bomQuantity){
							Vue.prototype.$message.error('请填写BOM套数');
						}else{
							let pcbInfo = []
							store.state.selectForm.xlsxDataList.map(res => {
								let a = res
								a['orderattr'] = "4"
								pcbInfo.push(a)
							})
							var numReg = /^\d+(\.\d+)?$/
							var bomtaxprice = false
							console.log("3213",pcbInfo)
							pcbInfo.map(res=>{
								if(res.bomtaxprice!==undefined){
									return bomtaxprice = numReg.test(res.bomtaxprice)
								}else{
									return bomtaxprice = true
								}
							})
							var bomamount = false
							pcbInfo.map(res=>{
								// console.log("pcbInfo2",numReg.test(res.bomamount))
								return bomamount = numReg.test(res.bomamount)
							})
							// console.log("pcbInfo",bomamount)
							// console.log("pcbInfo",store.state.selectForm.xlsxDataList)
							// console.log("pcbOderInfo",pcbOderInfo)
							if(bomtaxprice==false){
								Vue.prototype.$message.error('含税单价只能为数字或者小数~')
								return
							}else if(bomamount==false){
								Vue.prototype.$message.error('数量只能为数字或者小数~')
								return
							}else{
								console.log("123")
								let pcbOderInfo = {
									invoicetype: store.state.selectForm.invoiceType,
									invoiceway: store.state.selectForm.invoicingMethod,
									billletter: store.state.selectForm.contractTitle,
									uid: userInfo.id,
									ordername: store.state.selectForm.placingOrder,
									orderphone: store.state.selectForm.placingOrderPhone,
									techname: store.state.selectForm.technicians,
									techtel: store.state.selectForm.techniciansPhone,
									address: store.state.selectForm.shippingAddress,
									filename: store.state.selectForm.xlsxName.split('.')[0],
									purchasedesc: store.state.selectForm.purchasemark,
									orderattr: "4"
								}
								if(store.state.selectForm.bomQuantityIndex==1){
									pcbOderInfo['purchaseamount'] = store.state.selectForm.bomQuantity
								}else{
									pcbOderInfo['purchaseamount'] = store.state.selectForm.xlsxDataList.length
								}
								// console.log("pcbInfo",pcbInfo)
								// console.log("pcbOderInfo",pcbOderInfo)
								saveOrderAjax(e,pcbInfo,pcbOderInfo)
							}
						}
					}else{
						Vue.prototype.$message.error('请选择表单或者选择正确的表单~');
					}
				}
			}
		}

	} else {
		Vue.prototype.$message.error('请先进行登录');
		router.push({
			path: '/login',
		})
	}
}

export function saveOrderAjax(e, pcbInfo, pcbOderInfo) {
	let data = {
		pcbInfo: pcbInfo,
		orderInfo: pcbOderInfo
	}
	// data = JSON.stringify(data)
	// console.log(data)
	saveOrder(data).then(res => {
		var getUserInfo = sessionStorage.getItem("getUserInfo")
		if (res.code == "200") {
			Vue.prototype.$message({
				showClose: true,
				type: 'success',
				message: res.msg
			});
			if(e==1){
				if(getUserInfo){
					store.dispatch("setWeixinOrderIndex", {
					    weixinOrderIndex: 1,
					});
					router.push({
						path: '/myOrder',
					})
				}else{
					router.push({
						path: '/order/orderManagement/pcbOrder',
					})
				}
			}else if(e==2){
				if(getUserInfo){
					store.dispatch("setWeixinOrderIndex", {
					    weixinOrderIndex: 2,
					});
					router.push({
						path: '/myOrder',
					})
				}else{
					router.push({
						path: '/order/orderManagement/smtOrder',
					})
				}
			}else if(e==3){
				if(getUserInfo){
					store.dispatch("setWeixinOrderIndex", {
					    weixinOrderIndex: 3,
					});
					router.push({
						path: '/myOrder',
					})
				}else{
					router.push({
						path: '/order/orderManagement/seelOrder',
					})
				}
			}else if(e==4){
				store.dispatch("setXlsxName", {
				    xlsxName: null,
				});
				store.dispatch("setXlsxDataList", {
				    xlsxDataList: [],
				});
				store.dispatch("setPlainOptions", {
				    plainOptions: null,
				});
				store.dispatch("setBomForm", {
				    bomForm: null,
				});
				if(getUserInfo){
					store.dispatch("setWeixinOrderIndex", {
					    weixinOrderIndex: 4,
					});
					router.push({
						path: '/myOrder',
					})
				}else{
					router.push({
						path: '/order/orderManagement/bomOrder',
					})
				}
			}
		} else {
			Vue.prototype.$message.error(res.msg);
		}
	})
}