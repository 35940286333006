import request from '@/utils/request'

export default {
    // 新增采购订单
    addSale(data) {
        return request({
            url: '/sale/addSale',
            method: 'post',
            data: data
        })
    },
    // 更新采购订单
    updateProcQuot(data) {
        return request({
            url: '/sale/updateProcQuot',
            method: 'post',
            data: data
        })
    },
    // 分配销售
    updateSale(data) {
        return request({
            url: '/sale/updateSale',
            method: 'post',
            data: data
        })
    },
    // 分配采购
    getPoPrice(data) {
        return request({
            url: '/sale/getPoPrice',
            method: 'post',
            data: data
        })
    },
    // 删除采购订单
    deleteProcQuotById(data) {
        return request({
            url: '/sale/deleteProcQuotById',
            method: 'post',
            data: data
        })
    },
    // 采购订单列表接口
    getOrderList(data) {
        return request({
            url: '/sale/getOrderList',
            method: 'post',
            data: data
        })
    },
    // 采购订单列表接口
    getPriceList(data) {
        return request({
            url: '/sale/getPriceList',
            method: 'post',
            data: data
        })
    },
    // 销售订单列表接口
    getSaleOrderList(data) {
        return request({
            url: '/sale/getSaleOrderList',
            method: 'post',
            data: data
        })
    },
    // 销售返点订单列表接口
    getRebateOrderList(data) {
        return request({
            url: '/sale/getRebateOrderList',
            method: 'post',
            data: data
        })
    },
    // 销售出库订单列表
    getSaleOutList(data) {
        return request({
            url: '/sale/getSaleOutList',
            method: 'post',
            data: data
        })
    },
    // 回款
    updatePoOrder(data) {
        return request({
            url: '/purchase/updatePoOrder',
            method: 'post',
            data: data
        })
    },
    // 出库明细列表
    getOutDetailList(data) {
        return request({
            url: '/sale/getOutDetailList',
            method: 'post',
            data: data
        })
    },
    // 订单明细信息
    getOrderInfo(data) {
        return request({
            url: '/sale/getOrderInfo',
            method: 'post',
            data: data
        })
    },
    // 采购列表信息
    getOrderPrice(data) {
        return request({
            url: '/sale/getOrderPrice',
            method: 'post',
            data: data
        })
    },
    // 销售下单
    saveOrderPrice(data) {
        return request({
            url: '/sale/saveOrderPrice',
            method: 'post',
            data: data
        })
    },
    // 销售审核
    orderAudit(data) {
        return request({
            url: '/sale/orderAudit',
            method: 'post',
            data: data
        })
    },
    // 销售报价获取采购报价信息
    getQuot(data) {
        return request({
            url: '/sale/getQuot',
            method: 'post',
            data: data
        })
    },
    // 确认报价
    confirmPrice(data) {
        return request({
            url: '/sale/confirmPrice',
            method: 'post',
            data: data
        })
    },
    // 保存报价
    saletoPrice(data) {
        return request({
            url: '/sale/saletoPrice',
            method: 'post',
            data: data
        })
    },
    // 关联公司
    ossCom(data) {
        return request({
            url: '/sale/ossCom',
            method: 'post',
            data: data
        })
    },
    // 采购退货接口
    getInList(data) {
        return request({
            url: '/sale/getInList',
            method: 'post',
            data: data
        })
    },
    // 采购退货接口
    addReturn(data) {
        return request({
            url: '/sale/addReturn',
            method: 'post',
            data: data
        })
    },
    // 采购退货申请列表接口
    getApplyList(data) {
        return request({
            url: '/sale/getApplyList',
            method: 'post',
            data: data
        })
    },
    // 采购退货审核接口
    processReturn(data) {
        return request({
            url: '/sale/processReturn',
            method: 'post',
            data: data
        })
    },
    // 退货款列表
    getReturnFundList(data) {
        return request({
            url: '/sale/getReturnFundList',
            method: 'post',
            data: data
        })
    },
    // 返点申请提交
    getReturnFund(data) {
        return request({
            url: '/sale/getReturnFund',
            method: 'post',
            data: data
        })
    },
    // 币种汇率
    getExchangerate(data) {
        return request({
            url: '/getExchangerate',
            method: 'post',
            data: data
        })
    },
    // 业绩列表
    getComiList(data) {
        return request({
            url: '/finance/getProcComiList',
            method: 'post',
            data: data
        })
    },
    // 销售/采购业绩提城列表
    getComiMonthList(data) {
        return request({
            url: '/finance/getComiMonthList',
            method: 'post',
            data: data
        })
    },
    // 销售申请退货款
    applyReturnFund(data) {
        return request({
            url: '/sale/applyReturnFund',
            method: 'post',
            data: data
        })
    },
    // 再次获取下单数据
    getPcbInfoList(data) {
        return request({
            url: '/sale/getPcbInfoList',
            method: 'post',
            data: data
        })
    },
    // 取消订单
    cancelOrder(data) {
        return request({
            url: '/sale/cancelOrder',
            method: 'post',
            data: data
        })
    },
    // 修改订单
    updateOrder(data) {
        return request({
            url: '/sale/updateOrder',
            method: 'post',
            data: data
        })
    },

	// 退款银行账号
	getBankNo(data) {
	    return request({
	        url: '/funds/getBankNo',
	        method: 'post',
	        data: data
	    })
	},

	// 审核详情数据
	getConfirmPcbInfoList(data) {
	    return request({
	        url: '/sale/getConfirmPcbInfoList',
	        method: 'post',
	        data: data
	    })
	},

	// 审核之后的订单详情数据
	getBillPcbInfoList(data) {
	    return request({
	        url: '/sale/getBillPcbInfoList',
	        method: 'post',
	        data: data
	    })
	},
	// 退款审核
    returnFundAudit(data) {
	    return request({
	        url: '/sale/returnFundAudit',
	        method: 'post',
	        data: data
	    })
	},
	// 销售业绩记录
	getSaleComiList(data) {
	    return request({
	        url: '/finance/getSaleComiList',
	        method: 'post',
	        data: data
	    })
	},
	// 计算接口
	getCalProfit(data) {
	    return request({
	        url: '/sale/getCalProfit',
	        method: 'post',
	        data: data
	    })
	},
	// 扣毛利退货列表接口
	selectNotInFaultDeduce(data) {
	    return request({
	        url: '/faultdeducegrossprofit/selectNotInFaultDeduce',
	        method: 'post',
	        data: data
	    })
	},
	// 扣毛利提交接口
	faultdeducegrossprofitSave(data) {
	    return request({
	        url: '/faultdeducegrossprofit/save',
	        method: 'post',
	        data: data
	    })
	},
	// 扣毛利列表接口
	getList(data) {
	    return request({
	        url: '/faultdeducegrossprofit/getList',
	        method: 'post',
	        data: data
	    })
	},
	// 扣毛利修改接口
	faultdeducegrossprofitUpdate(data) {
	    return request({
	        url: '/faultdeducegrossprofit/updateData',
	        method: 'post',
	        data: data
	    })
	},
	// 待开票列表接口
	getNotInvoceOrderInfoList(data) {
	    return request({
	        url: '/invoce/getNotInvoceOrderInfoList',
	        method: 'post',
	        data: data
	    })
	},
	// 待开票列表二级接口
	getNotInvoceWareOutList(data) {
	    return request({
	        url: '/invoce/getNotInvoceWareOutList',
	        method: 'post',
	        data: data
	    })
	},
	// 用毛利率反推销售报价
	getCalSalePriceByGrossprofit(data) {
	    return request({
	        url: '/sale/getCalSalePriceByGrossprofit',
	        method: 'post',
	        data: data
	    })
	}
}
