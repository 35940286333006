<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="modalOpen" :modal-title="formTitle" :adjust-size="false" modalHeight="300" modalWidth="350"
			@cancel="cancel">
			<div slot="content" layout="vertical">
				<el-form ref="ruleForm" :model="ruleForm" :rules="loginRules" label-width="">
					<el-form-item prop="mobile">
						<div class="inputDiv">
							<a-icon type="user" class="id_icon" />
							<el-input :placeholder="$t('login.right.mobile')" v-model="ruleForm.mobile" style="border: 0px;" type="text" />
						</div>
					</el-form-item>
					<el-form-item prop="smscode">
						<div style="display: flex;">
							<el-input style="margin-right: 20px;" v-model="ruleForm.smscode" type="text" auto-complete="off"
								:placeholder="$t('register.right.smscode')">
								<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
							</el-input>
							<el-button v-bind:class="{grey:isGrey,blue:!isGrey}" v-bind:disabled="dis" type="primary"
								@click="getCode">
								<span v-if="show">{{$t('register.right.smscodeButton1')}}</span>
								<span v-else>{{$t('register.right.smscodeButton2')}}({{count}}s)</span>
							</el-button>
						</div>
					</el-form-item>
					<!-- <el-form-item>
						<no-captcha style="width: 100%;" @slideCallback="finishSlide" :reload="reload" />
					</el-form-item> -->
				</el-form>
			</div>
			<template slot="footer">
				<a-button type="primary" @click="handleLogin">
					登录
				</a-button>
				<a-button @click="cancel">
					取消
				</a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
	import {
		getCode
	} from '@/api/home/index.js'
	export default {
		components: {
			AntModal
		},
		props: {
			open: { // 按钮默认文本
				type: Boolean,
				default: false
			},
			details: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				modalOpen: this.open, //弹窗打开关闭
				formTitle: "登录", //弹框名
				customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
				ruleForm: {
					mobile: "",
					smscode: "",
					type:2
					// rememberMe: false,
				},
				loginRules: {
					mobile: [{
						required: true,
						trigger: "blur",
						message: "请输入您的手机号码"
					},
					{
						pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
						message: "请输入正确的手机号码",
						trigger: "blur"
					}],
					pword: [{
							required: true,
							trigger: "blur",
							message: "请输入您的密码"
						},
						{
							min: 8,
							max: 12,
							message: "长度在 8 到 12 个字符",
							trigger: "blur",
						}
					],
					smscode:[{
						required: true,
						trigger: "blur",
						message: "请输入验证码"
					}]
				},
				dis: false,
				show: true,
				isGrey: true, //按钮样式
				timer: null, //设置计时器,
				count: "",
			}
		},

		created() {

		},

		mounted() {

		},
		
		watch:{
			open(val){
				this.modalOpen=val
			}
		},

		methods: {
			//------------------------取消按钮-------------------------------
			cancel: function() {
				// console.log("cancel")
				this.modalOpen = false;
				this.$emit('close');
			},

			//-------------------------登录接口---------------------------------
			handleLogin: function() {
				// console.log(this.ruleForm)
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						this.$message.success('登录成功')
						this.modalOpen = false
						this.$emit('ok',this.ruleForm)
					} else {
						return false
					}
				});
			},
			
			//----------------------获取二维码-----------------------------
			getCode: function() {
				let data = {
					mobile: this.ruleForm.mobile
				}
				getCode(data).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.isGrey = false;
							this.show = false;
							this.dis = true;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.dis = false;
									this.isGrey = true;
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	
	input[type=text]:focus {
		outline: none;
		
	}
	
	input[type=password]:focus {
		outline: none;
		
	}
	
	/deep/.el-input__inner {
		border: 0px;
		box-shadow: 0 0 0 0px;
	}
	
	.inputDiv{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #dddddd;
		
		.id_icon{
			color: #d81e06;
			font-size: 20px;
			border-right: 2px solid #d81e06;
			padding-right: 10px;
		}
	}
	
	.register{
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.register2{
		padding: 0 20px 20px 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>