<template>
	<div>
		<div class="steelMeshPricing" v-if="windowWidth>=1200">
			<div class="smp_left">
				<div class="smp_le_div">
					<div class="smp_le_di_title">{{$t('valuation.steel.title')}}</div>
					<!-- <div style="font-size: 0.8vw;color: red;padding-left: 1vw;">{{$t('valuation.steel.content1')}}</div> -->
				</div>
				<steel-mesh-pricing :dataList="dataList"></steel-mesh-pricing>
				<div class="smp_le_div2">
					<div class="smp_le_di2_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="smp_le_di2_text">{{$t('valuation.pcb.text5')}}</div>
				</div>
				<order-information :dataList="dataList"></order-information>
			</div>
		
			<div class="smp_right">
				<div class="smp_ri_div">
					<div class="smp_ri_di_title">钢网报价和交期</div>
					<div class="smp_ri_di_text">
						其他费用：￥{{calcPcbPriceData.otherprice || "0.00"}}</div>
		
					<div class="smp_ri_di_text2">
						总计:
						<div class="smp_ri_di_te_stentfee">
							￥{{$store.state.selectForm.stentfee || "0.00"}}</div>
					</div>
					<div class="smp_ri_di_buttonDiv">
						<!-- <div class="smp_ri_di_bd_text" @click="dataInfo">提交订单</div> -->
						<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
							@click.native.prevent="dataInfo">
							<span v-if="!loading">{{$t('valuation.quotation.button')}}</span>
							<span v-else>{{$t('valuation.quotation.button')}}...</span>
						</el-button>
					</div>
				</div>
			</div>
		</div>
		
		<div class="steelMeshPricing2" v-if="windowWidth<=1199">
			<div class="smp_left">
				<div class="smp_le_div">
					<div class="smp_le_di_title">{{$t('valuation.steel.title')}}</div>
					<!-- <div style="font-size: 0.8vw;color: red;padding-left: 1vw;">{{$t('valuation.steel.content1')}}</div> -->
				</div>
				<steel-mesh-pricing :dataList="dataList"></steel-mesh-pricing>
				<div class="smp_le_div2">
					<div class="smp_le_di2_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="smp_le_di2_text">{{$t('valuation.pcb.text5')}}</div>
				</div>
				<order-information :dataList="dataList"></order-information>
			</div>
			
			<div style="background: #fff;box-shadow: 0 15px 30px  rgba(0, 0, 0, .4);padding: 0 20px;position: fixed;bottom: 0;width: 100%;left: 0;display: flex;align-items: center;height: 60px;">
				<div style="padding-top: 10px;font-size: 12px;padding-bottom: 10px;display: flex;line-height: 2vw;">
					{{$t('valuation.quotation.text13')}}:
					<div style="font-size: 16px;color: red;font-weight: bold;padding-left: 10px;">
						￥{{$store.state.selectForm.stentfee || "0.00"}}</div>
						
					<!-- <div style="padding-left: 20px;color: #409EFF;" @click="drawerClick">{{$i18n.locale=="en" ? 'Quotation Details' : '报价明细'}}</div> -->
				</div>
				
				<div style="position: absolute;right: 0;background: #d1102d;padding: 0 40px;bottom: 0;color: #fff;height: 60px;display: flex;align-items: center;" @click="dataInfo">{{$t('valuation.quotation.button')}}</div>
			</div>
		</div>
		
		<login-model :open="loginOpen" @ok="submitForm" @close="close"></login-model>
	</div>
</template>

<script>
	import {
		dataInJs
	} from './data.js';
	import steelMeshPricing from './steelMeshPricing/steelMeshPricing.vue'
	import orderInformation from './selectForm/orderInformation.vue'
	import loginModel from './loginModel.vue'
	import {
		calcStenPriceRturn
	} from './calcStenPrice.js'
	import {
		Notification,
		MessageBox,
		Message,
		Loading
	} from 'element-ui'
	import {
		getMyAddList
	} from '@/api/userInfo/index'
	import {
		saveOrderReturn
	} from './submit.js'
	import {
		saveOrder,
		savePcb,
		clientLand
	} from '../../../api/home/index.js'
	import Cookies from "js-cookie";
	export default {
		components: {
			steelMeshPricing,
			orderInformation,
			loginModel
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				dataList: null, //list数据
				shippingMethod: 0, //出货方式
				calcPcbPriceData: null, //获取计价数据
				userInfo: null,
				loginOpen:false,
				loading: false,
			}
		},

		created() {
			calcStenPriceRturn()
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.calcPcbPriceData = this.$store.state.selectForm.calcPcbPriceData
			this.dataList = this.$store.state.selectForm.dataInfoContent
		},

		mounted() {

		},

		watch: {
			"$store.state.selectForm.calcPcbPriceData"(val) {
				this.calcPcbPriceData = val
			}
		},

		computed: {

		},

		methods: {
			dataInfo: function() {
				this.loading=true;
				if(this.userInfo!==undefined){
					let queryParam ={
						pageNum: 1,
						pageSize: 50,
						uid: this.userInfo.id,
						isdelete:0
					}
					getMyAddList(queryParam).then(res=>{
						if(res.data.content.length>0){
							saveOrderReturn(3,this.calcPcbPriceData)
							this.loading=false;
						}else{
							this.$message.error('请新增订单地址~')
							this.loading=false;
						}
					})
				}else{
					this.loginOpen=true;
				}
			},
			
			submitForm:function(e){
				clientLand(e).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						Cookies.set("userInfo2", JSON.stringify(res.data), {
							expires: 30
						});
						Cookies.set("role2", res.role, {
							expires: 30
						});
						this.userInfo = Cookies.get("userInfo2")
						if(this.userInfo){
							this.userInfo = JSON.parse(this.userInfo)
						}
						this.dataInfo();
						location.reload();
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			close:function(){
				this.loginOpen=false;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.steelMeshPricing{
			display: flex;
			width: 100%;
			padding: 0 200px;
			margin-top: 20px;
			
			.smp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.smp_le_div{
					display: flex;
					padding: 1vw;
					align-items: center;
					
					.smp_le_di_title{
						font-size: 1vw;
						font-weight: bold;
					}
				}
				
				.smp_le_div2{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.smp_le_di2_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.smp_le_di2_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
			
			.smp_right{
				width: 30%;
				
				.smp_ri_div{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.smp_ri_di_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.smp_ri_di_text{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						border-bottom: 1px solid #e8eaeb;
					}
					
					.smp_ri_di_text2{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.smp_ri_di_te_stentfee{
							font-size: 2vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.smp_ri_di_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.smp_ri_di_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.steelMeshPricing{
			display: flex;
			width: 100%;
			padding: 0 200px;
			margin-top: 20px;
			
			.smp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.smp_le_div{
					display: flex;
					padding: 1vw;
					align-items: center;
					
					.smp_le_di_title{
						font-size: 1vw;
						font-weight: bold;
					}
				}
				
				.smp_le_div2{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.smp_le_di2_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.smp_le_di2_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
			
			.smp_right{
				width: 30%;
				
				.smp_ri_div{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.smp_ri_di_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.smp_ri_di_text{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						border-bottom: 1px solid #e8eaeb;
					}
					
					.smp_ri_di_text2{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.smp_ri_di_te_stentfee{
							font-size: 2vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.smp_ri_di_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.smp_ri_di_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.steelMeshPricing{
			display: flex;
			width: 100%;
			padding: 0 140px;
			margin-top: 20px;
			
			.smp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.smp_le_div{
					display: flex;
					padding: 1vw;
					align-items: center;
					
					.smp_le_di_title{
						font-size: 1vw;
						font-weight: bold;
					}
				}
				
				.smp_le_div2{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.smp_le_di2_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.smp_le_di2_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
			
			.smp_right{
				width: 30%;
				
				.smp_ri_div{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.smp_ri_di_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.smp_ri_di_text{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						border-bottom: 1px solid #e8eaeb;
					}
					
					.smp_ri_di_text2{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.smp_ri_di_te_stentfee{
							font-size: 2vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.smp_ri_di_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.smp_ri_di_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.steelMeshPricing{
			display: flex;
			width: 100%;
			padding: 0 30px;
			margin-top: 20px;
			
			.smp_left{
				width: 70%;
				border: 1px solid #e8eaeb;
				
				.smp_le_div{
					display: flex;
					padding: 1vw;
					align-items: center;
					
					.smp_le_di_title{
						font-size: 1vw;
						font-weight: bold;
					}
				}
				
				.smp_le_div2{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.smp_le_di2_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.smp_le_di2_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
			
			.smp_right{
				width: 30%;
				
				.smp_ri_div{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.smp_ri_di_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.smp_ri_di_text{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						border-bottom: 1px solid #e8eaeb;
					}
					
					.smp_ri_di_text2{
						padding-top: 0.5vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.smp_ri_di_te_stentfee{
							font-size: 2vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.smp_ri_di_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.smp_ri_di_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.steelMeshPricing2{
			display: flex;
			width: 100%;
			padding: 0 30px;
			margin-top: 20px;
			padding-bottom: 60px;
			
			.smp_left{
				width: 100%;
				border: 1px solid #e8eaeb;
				
				.smp_le_div{
					display: flex;
					padding: 1vw;
					align-items: center;
					
					.smp_le_di_title{
						font-size: 1vw;
						font-weight: bold;
					}
				}
				
				.smp_le_div2{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.smp_le_di2_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.smp_le_di2_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
			}
			
		}
	}
	
	@media only screen and (max-width:767px) {
		.steelMeshPricing2{
			display: flex;
			width: 100%;
			padding: 0 30px;
			margin-top: 20px;
			padding-bottom: 60px;
			
			.smp_left{
				width: 100%;
				border: 1px solid #e8eaeb;
				
				.smp_le_div{
					display: flex;
					padding: 10px;
					align-items: center;
					
					.smp_le_di_title{
						font-size: 12px;
						font-weight: bold;
					}
				}
				
				.smp_le_div2{
					padding: 10px;
					align-items: center;
					background: #f8f8f8;
					
					.smp_le_di2_title{
						font-size: 12px;
						font-weight: bold;
					}
					
					.smp_le_di2_text{
						font-size: 10px;
						color: #888;
						padding-top: 5px;
					}
				}
			}
			
		}
	}
</style>
