<template>
	<div>
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<valuation-tabs :windowWidth="windowWidth" :windowHeight="windowHeight" @tabsClick="tabsClick"></valuation-tabs>
		<div v-if="tabsIndex==0">
			<select-form :windowWidth="windowWidth" :windowHeight="windowHeight"></select-form>
		</div>
		<!-- <div v-if="tabsIndex==1">
			<flexible-board></flexible-board>
		</div> -->
		<div v-if="tabsIndex==2">
			<SMTpricing :windowWidth="windowWidth" :windowHeight="windowHeight"></SMTpricing>
		</div>
		<div v-if="tabsIndex==3">
			<steel-mesh-pricing :windowWidth="windowWidth" :windowHeight="windowHeight"></steel-mesh-pricing>
		</div>
		<div v-if="tabsIndex==4">
			<bom :windowWidth="windowWidth" :windowHeight="windowHeight"></bom>
		</div>
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import valuationTabs from './components/tabs.vue';
	import selectForm from './components/selectForm.vue';
	import flexibleBoard from './components/flexibleBoard.vue';
	import SMTpricing from './components/SMTpricing.vue';
	import steelMeshPricing from './components/steelMeshPricing.vue';
	import { changeText } from '../../utils/changeText.js';
	import bottom from '@/components/bottom/index.vue';
	import bom from './components/bom.vue';
	import dictApi from '@/api/system/dict'
	export default {
		components: {
			top,
			valuationTabs,
			selectForm,
			flexibleBoard,
			SMTpricing,
			steelMeshPricing,
			bottom,
			bom,
			right
		},
		data() {
			return {
				tabsIndex: 0,
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},

		created() {
			
		},

		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
			this.tabsIndex = this.$store.state.Layout.topTabsIndex;
			this.getDictsByPid();
		},

		watch: {
		},

		computed: {
		},

		methods: {
			
			//----------------获取字典接口----------------------
			getDictsByPid:function(){
				dictApi.getDictsByPid({
					pid: '1,16,4,135,177,66,23,28,33,48,53,512,87,516,77,164,160,97,519,522,524,528,532,535,247,540,557,565,571,267,272,574,276,577'
				}).then((res) => {
					// console.log(res)
					this.$store.dispatch("setDataInfoContent", {
					    dataInfoContent: res.content,
					});
				})
			},
			
			//------------------tabs点击------------------------
			tabsClick: function(e) {
				this.tabsIndex = e;
			},
		}
	}
</script>

<style lang="less" scoped>

</style>
