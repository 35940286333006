import { calcStenPrice } from '@/api/home/index.js' 
import store from '@/store'
import Cookies from "js-cookie";

export function calcStenPriceRturn() {
	var userInfo = Cookies.get("userInfo2")
	if(userInfo){
		userInfo = JSON.parse(userInfo)
	}
	if(store.state.selectForm.steelMeshNumber !== '' && store.state.selectForm.steelMeshNumber !== null){
		let data = {
			stentype: store.state.selectForm.steelTypeIndex,
			stenspec: store.state.selectForm.steelMeshSpecifications,
			stennanometer: store.state.selectForm.nanoprocessing,
			stenway: store.state.selectForm.smtProductionMethod,
			stencount: store.state.selectForm.steelMeshNumber,
			stenmark: store.state.selectForm.markRequirements,
			stenuse: store.state.selectForm.steelMeshUsage,
			stenthick: store.state.selectForm.thicknessIndex,
			stentechart: store.state.selectForm.polishProcessIndex,
			// smtsenddate:store.state.selectForm.steelOtherRequirements,
			stencilmark: store.state.selectForm.steelOtherRequirements,
		}
		store.dispatch("setSteelOrderInfo", {
			steelOrderInfo: data,
		});
		calcStenPrice(data).then(res=>{
			// console.log("calcStenPrice",res)
			store.dispatch("setStentfee", {
			    stentfee: res.data,
			});
		})
	}else{
		store.dispatch("setSteelOrderInfo", {
			steelOrderInfo: null,
		});
	}
}