import { dataInJs } from './data.js';

export function dataInfo(e,item){
	console.log(item[5])
	for(var i = 0; i < item.length; i ++){
		if(i==3 || i==4 || i==5){
			item[i].map(res2=>{
				res2.isdele=false
			})
		}
	}
	switch(e){
		case 0:
			var a = item;
			a[3].forEach(res=>{
				if(res.name == "生益S1000H TG150"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
		case 1:
			var a = item;
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			// console.log(a)
			return a;
		case 2:
			var a = item;
			a[4].forEach(res=>{
				if(res.name == "0.2" || res.name == "0.3"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130" || res.name == "国纪GF21-AF0 TG120"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[5].forEach(res=>{
				if(res.name !== "1/1oz" && res.name !== "2/2oz" && res.name !== "3/3oz" && res.name !== "4/4oz"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
		case 3:
			var a = item;
			a[4].forEach(res=>{
				if(res.name == "0.2" || res.name == "0.3" || res.name == "0.4" || res.name == "0.6"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130" || res.name == "国纪GF21-AF0 TG120"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[5].forEach(res=>{
				if(res.name !== "1/1oz" && res.name !== "2/2oz" && res.name !== "3/3oz" && res.name !== "4/4oz"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
		case 4:
			var a = item;
			a[4].forEach(res=>{
				if(res.name == "0.2" || res.name == "0.3" || res.name == "0.4" || res.name == "0.6" || res.name == "0.8"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130" || res.name == "国纪GF21-AF0 TG120"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[5].forEach(res=>{
				if(res.name !== "1/1oz" && res.name !== "2/2oz"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
		case 5:
			var a = item;
			a[4].forEach(res=>{
				if(res.name == "0.2" || res.name == "0.3" || res.name == "0.4" || res.name == "0.6" || res.name == "0.8" || res.name == "1" || res.name == "1.2"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130" || res.name == "国纪GF21-AF0 TG120" || res.name == "建滔KB6165F TG150" || res.name == "生益S1000H TG150"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[5].forEach(res=>{
				if(res.name !== "1/1oz"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
		case 6:
			var a = item;
			a[4].forEach(res=>{
				if(res.name == "0.2" || res.name == "0.3" || res.name == "0.4" || res.name == "0.6" || res.name == "0.8" || res.name == "1" || res.name == "1.2"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130" || res.name == "国纪GF21-AF0 TG120" || res.name == "建滔KB6165F TG150" || res.name == "生益S1000H TG150"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[5].forEach(res=>{
				if(res.name !== "1/1oz"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
		case 7:
			var a = item;
			a[4].forEach(res=>{
				if(res.name == "0.2" || res.name == "0.3" || res.name == "0.4" || res.name == "0.6" || res.name == "0.8" || res.name == "1" || res.name == "1.2" || res.name == "1.4" || res.name == "1.6"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[3].forEach(res=>{
				if(res.name == "国纪GF212 TG130" || res.name == "国纪GF21-AF0 TG120" || res.name == "建滔KB6165F TG150" || res.name == "生益S1000H TG150"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			a[5].forEach(res=>{
				if(res.name !== "1/1oz"){
					res.isdele=true;
				}else{
					res.isdele=false;
				}
			})
			return a;
	}
}