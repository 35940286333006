<template>
	<div style="display: flex;width: 100%;padding: 0 200px;">
		<div style="width: 70%;border: 1px solid #e8eaeb;">
			<div style="padding: 1vw;align-items: center;">
				<div style="font-size: 1vw;font-weight: bold;">{{$t('valuation.FPC.title1')}}</div>
				<div style="font-size: 0.8vw;color: #888;padding-top: 10px;">{{$t('valuation.FPC.text1')}}</div>
			</div>
			<flexible-board :dataList="dataList"></flexible-board>
			<flexible-board-tow :dataList="dataList"></flexible-board-tow>
			<div style="padding: 1vw;align-items: center;background: #f8f8f8;">
				<div style="font-size: 1vw;font-weight: bold;">{{$t('valuation.FPC.title2')}}</div>
				<div style="font-size: 0.8vw;color: #888;padding-top: 10px;">{{$t('valuation.FPC.text2')}}</div>
			</div>
			<individualization :dataList="dataList"></individualization>
		</div>

		<div style="width: 30%;">
			<div style="padding: 20px;border: 1px solid #e8eaeb;margin-left: 20px;">
				<div style="font-weight: bold;font-size: 1vw;">{{$t('valuation.quotation.title')}}</div>
				<!-- <div style="padding-top: 0.5vw;font-size: 0.5vw;">快递费：￥0.00</div> -->
				<div style="padding-top: 1vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text1')}}：￥{{calcPcbPriceData.engineerfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text2')}}：￥{{calcPcbPriceData.boardfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text3')}}：￥{{calcPcbPriceData.filmfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text4')}}：￥{{calcPcbPriceData.panelfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text5')}}：￥{{calcPcbPriceData.colorfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text6')}}：￥{{calcPcbPriceData.sprayfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text7')}}：￥{{calcPcbPriceData.testfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text8')}}：￥{{calcPcbPriceData.urgentfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text9')}}：￥{{calcPcbPriceData.taxationfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text10')}}：￥{{calcPcbPriceData.specfee || "0.00"}}</div>
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
					{{$t('valuation.quotation.text11')}}：￥{{calcPcbPriceData.discountfee || "0.00"}}</div>
				<div
					style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;border-bottom: 1px solid #e8eaeb;">
					{{$t('valuation.quotation.text12')}}：￥{{calcPcbPriceData.otherprice || "0.00"}}</div>
		
				<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;display: flex;line-height: 2vw;">
					{{$t('valuation.quotation.text13')}}:
					<div style="font-size: 2vw;color: red;font-weight: bold;padding-left: 0.5vw;">
						￥{{calcPcbPriceData.orderno || "0.00"}}</div>
				</div>
				<div style="position: relative;padding-top:20px;text-align: right;z-index: 1;">
					<div style="padding: 10px 40px;cursor: pointer;display: inline-block;font-size: 16px;border: 1px solid #d1102d;background: #d1102d;color: #fff;box-shadow: 0 5px 7px rgb(255 104 34 / 28%);border-radius: 100px;"
						@click="dataInfo">{{$t('valuation.quotation.button')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dataInJs
	} from './data.js';
	import flexibleBoard from './flexibleBoard/flexibleBoard.vue'
	import flexibleBoardTow from './flexibleBoard/flexibleBoardTow.vue'
	import individualization from './flexibleBoard/individualization.vue'
	import {
		calcPcbPriceReturn
	} from './saveOrder.js'
	import {
		Notification,
		MessageBox,
		Message,
		Loading
	} from 'element-ui'
	import {
		saveOrder,
		savePcb
	} from '../../../api/home/index.js'
	import Cookies from "js-cookie";
	export default {
		components: {
			flexibleBoard,
			flexibleBoardTow,
			individualization
		},
		data() {
			return {
				dataList: dataInJs(), //list数据
				shippingMethod: 0, //出货方式
				calcPcbPriceData: null, //获取计价数据
				userInfo: null,

			}
		},

		created() {
			calcPcbPriceReturn()
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.calcPcbPriceData = this.$store.state.selectForm.calcPcbPriceData
		},

		mounted() {

		},

		watch: {
			"$store.state.selectForm.calcPcbPriceData"(val) {
				this.calcPcbPriceData = val
			}
		},

		computed: {

		},

		methods: {
			// dataInfo: function() {
			// 	console.log(this.$store.state.checkListData)
			// 	if (this.$store.state.boardLength == '') {
			// 		this.$message.error('请输入板子长度');
			// 	} else if (this.$store.state.boardWidth == '') {
			// 		this.$message.error('请输入板子宽度');
			// 	} else if (this.$store.state.boardNumber == '') {
			// 		this.$message.error('请输入板子数量');
			// 	} else if (this.$store.state.checkListData == "") {
			// 		this.$message.error('请选择特殊工艺');
			// 	} else if (this.userInfo == null) {
			// 		this.$message.error('请先进行登录');
			// 		this.$router.push({
			// 			path: '/login',
			// 		})
			// 	} else {
			// 		let data = {
			// 			uid: String(this.userInfo.uid),
			// 			pcbcount: String(this.$store.state.boardNumber),
			// 			invoicetype: String(this.$store.state.invoiceType),
			// 			invoiceinfo: String(this.$store.state.invoicingMethod),
			// 			// invoicemail: "hesure1@163.com",
			// 			// billletter: "中国船舶有限公司青岛分公司",
			// 			// deliverprice: "45.00",
			// 			totalprice: String(this.calcPcbPriceData.orderno),
			// 			pcbprice: String(this.calcPcbPriceData.orderno),
			// 			engineerfee: String(this.calcPcbPriceData.engineerfee),
			// 			sprayfee: String(this.calcPcbPriceData.sprayfee),
			// 			panelfee: String(this.calcPcbPriceData.panelfee),
			// 			testfee: String(this.calcPcbPriceData.testfee),
			// 			// smtservice: "1",
			// 			orderattr: "1",
			// 			// techname: "苏若拉",
			// 			// techtel: "13621050254",
			// 			// ordername: "秀秀",
			// 			sellmodel: String(this.$store.state.shippingMethod),
			// 			// panelrules: "1",
			// 			// forkplate: "1",
			// 			// deliverylength: "35",
			// 			// deliverywidth: "110",
			// 			// techlength: "33",
			// 			// techwidth: "1",
			// 			// 106: "1",
			// 			pcblength: String(this.$store.state.boardLength),
			// 			pcbwidth: String(this.$store.state.boardWidth),
			// 			// pcbarea: String(this.$store.state.boardArea),
			// 			designcount: String(this.$store.state.designDocuments),
			// 			pcbtype: String(this.$store.state.boardType),
			// 			pcbbrand: String(this.$store.state.TGsub),
			// 			// materialtype: "2",
			// 			pcbthick: String(this.$store.state.BoardThickness),
			// 			minlinewidth: String(this.$store.state.minimumLineWidth),
			// 			minhole: String(this.$store.state.minimumThroughHole),
			// 			holecolor: String(this.$store.state.resistanceWeldingColor),
			// 			fontcolor: String(this.$store.state.characterColor),
			// 			holespray: String(this.$store.state.solderPadSprayCoating),
			// 			// holethickid: "13",
			// 			// holematerial: "5",
			// 			// weldcover: "1",
			// 			goldthick: String(this.$store.state.goldthick),
			// 			testmethod: String(this.$store.state.TestMethodRadio),
			// 			cuthick: String(this.$store.state.copperThickness),
			// 			spectech: String(this.$store.state.checkListData)
			// 		}
			// 		data = JSON.stringify(data)
			// 		console.log(data)
			// 		saveOrder(data).then(res => {
			// 			if(res.code == "200"){
			// 				this.$message({
			// 					showClose: true,
			// 					type: 'success',
			// 					message: res.msg
			// 				});
			// 			}else{
			// 				this.$message.error(res.msg);
			// 			}
			// 		})
			// 	}
			// }
		}
	}
</script>

<style lang="less" scoped>

</style>
