<template>
	<div style="padding: 1vw;">
		<!-- 钢网类型 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title1')}}：</div>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="steelMeshTypeIndex" :dataList="dataList1[24]" @select="steelTypeSelect"></single-choice>
			</div>
		</div>
		
		<!-- 钢网规格 -->
		<div style="display: flex;padding-top: 20px;" v-if="steelMeshTypeIndex==0">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.steel.steel.title2')}}：</div>
			</div>
			<div class="be_content">
				<el-select class="be_ct_select" v-model="SteelMeshSpecifications" placeholder="">
				    <el-option
				      v-for="(item,index) in dataList1[25]"
				      :key="index"
				      :label="$i18n.locale=='en' ? item.enname : item.name"
				      :value="index+1">
				    </el-option>
				  </el-select>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;" v-if="steelMeshTypeIndex==1">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.steel.steel.title2')}}：</div>
			</div>
			<div class="be_content">
				<el-select class="be_ct_select" v-model="SteelMeshSpecifications" placeholder="">
				    <el-option
				      v-for="(item,index) in dataList1[26]"
				      :key="index"
				      :label="$i18n.locale=='en' ? item.enname : item.name"
				      :value="index+1">
				    </el-option>
				  </el-select>
			</div>
		</div>
		
		<div style="display: flex;padding-top: 20px;" v-if="steelMeshTypeIndex==2">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.steel.steel.title2')}}：</div>
			</div>
			<div class="be_content">
				<el-select class="be_ct_select" v-model="SteelMeshSpecifications" placeholder="">
				    <el-option
				      v-for="(item,index) in dataList1[27]"
				      :key="index"
				      :label="$i18n.locale=='en' ? item.enname : item.name"
				      :value="index+1">
				    </el-option>
				  </el-select>
			</div>
		</div>
		
		<!-- 纳米处理 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title3')}}：</div>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="nanoprocessing" :dataList="dataList1[28]" @select="nanoprocessingSelect"></single-choice>
			</div>
		</div>
		
		<!-- 制作方式 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title4')}}：</div>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="smtProductionMethod" :dataList="dataList1[29]" @select="smtProductionMethodSelect"></single-choice>
			</div>
		</div>
		
		<!-- 开钢网数量 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.steel.steel.title5')}}：</div>
			</div>
			<div class="be_content">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="steelMeshNumber" placeholder="" />
					<!-- <span class="x">PCS</span> -->
				</div>
			</div>
		</div>
		
		<!-- MARK需求 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title6')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.steel.steel.content1')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="markRequirements" :dataList="dataList1[30]" @select="markRequirementsSelect"></single-choice>
			</div>
		</div>
		
		<!-- 钢网用途 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title7')}}：</div>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="steelMeshUsage" :dataList="dataList1[31]" @select="steelMeshUsageSelect"></single-choice>
			</div>
		</div>
		
		<!-- 厚度 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title8')}}：</div>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="thicknessIndex" :dataList="dataList1[32]" @select="thicknessIndexSelect"></single-choice>
			</div>
		</div>
		
		<!-- 抛光工艺 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title9')}}：</div>
			</div>
			<div class="be_content">
				<single-choice :dataIndex="polishProcessIndex" :dataList="dataList1[33]" @select="polishProcessIndexSelect"></single-choice>
			</div>
		</div>
		
		<!-- 其它需求 -->
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_text">{{$t('valuation.steel.steel.title10')}}：</div>
			</div>
			<div class="be_content">
				<el-input
				  type="textarea"
				  :rows="2"
				  placeholder=""
				  v-model="steelOtherRequirements">
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import changeSingleChoice from '@/components/changeSingleChoice/index.vue';
	import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
	import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
	import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
	import {dataInfo} from '../dataHandling.js';
	import {
		calcStenPriceRturn
	} from '../calcStenPrice.js'
	export default {
		components:{
			singleChoice,
			changeSingleChoice,
			singleChoiceImage,
			singleChoiceColor,
			singleChoicePopup
		},
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				dataList1:this.dataList,
				boardNumberPliesIndex:0,
				steelMeshTypeIndex:0,//钢网类型下标
				SteelMeshSpecifications:1,//钢网规格
				steelMeshNumber:null,//钢网数量
				textarea:null,
				nanoprocessing:0,//纳米处理
				smtProductionMethod:0,//smt制作方式
				markRequirements:0,//MARK需求
				steelMeshUsage:0,//钢网用途
				thicknessIndex:0,//厚度
				polishProcessIndex:0,//抛光工艺
				steelOtherRequirements:null,//其他需求
				list1:[{
					name:"不需要",
					enName:"Not required",
					type:true
				},
				{
					name:"需要",
					enName:"Need",
					type:true
				}]
			}
		},
		
		created() {
			this.steelMeshTypeIndex = this.$store.state.selectForm.steelTypeIndex - 1
			this.SteelMeshSpecifications = this.$store.state.selectForm.steelMeshSpecifications
			this.nanoprocessing = this.$store.state.selectForm.nanoprocessing-1
			this.steelMeshNumber = this.$store.state.selectForm.steelMeshNumber
			this.smtProductionMethod = this.$store.state.selectForm.smtProductionMethod - 1
			this.steelMeshUsage = this.$store.state.selectForm.steelMeshUsage - 1
			this.thicknessIndex = this.$store.state.selectForm.thicknessIndex - 1
			this.polishProcessIndex = this.$store.state.selectForm.polishProcessIndex - 1
			this.markRequirements = this.$store.state.selectForm.markRequirements - 1
			this.steelOtherRequirements = this.$store.state.selectForm.steelOtherRequirements
			// console.log(this.dataList1)
		},
		
		mounted() {
			
		},
		
		watch:{
			SteelMeshSpecifications(val){
				this.$store.dispatch("setSteelMeshSpecifications", {
				    steelMeshSpecifications: val,
				});
				calcStenPriceRturn()
			},
			steelMeshNumber(val){
				this.$store.dispatch("setSteelMeshNumber", {
				    steelMeshNumber: val,
				});
				console.log(val)
				if(val==''){
					this.$store.dispatch("setStentfee", {
					    stentfee: null,
					});
				}
				calcStenPriceRturn()
			},
			steelOtherRequirements(val){
				this.$store.dispatch("setSteelOtherRequirements", {
				    steelOtherRequirements: val,
				});
				calcStenPriceRturn()
			}
		},
		
		computed:{
			
		},
		
		methods:{
			//----------钢网类型----------------------
			steelTypeSelect:function(index){
				this.steelMeshTypeIndex=index
				// console.log(this.steelMeshTypeIndex)
				this.$store.dispatch("setSteelTypeIndex", {
				    steelTypeIndex: index + 1,
				});
				calcStenPriceRturn()
			},
			
			//-------------纳米处理-----------------------
			nanoprocessingSelect:function(index){
				this.nanoprocessing = index;
				this.$store.dispatch("setNanoprocessing", {
				    nanoprocessing: index + 1,
				});
				calcStenPriceRturn()
			},
			
			//-----------制作方式------------------
			smtProductionMethodSelect:function(index){
				this.smtProductionMethod = index
				this.$store.dispatch("setSmtProductionMethod", {
				    smtProductionMethod: index + 1,
				});
				calcStenPriceRturn()
			},
			
			//------------mark需求-------------------
			markRequirementsSelect:function(index){
				this.markRequirements = index
				this.$store.dispatch("setMarkRequirements", {
				    markRequirements: index + 1,
				});
				calcStenPriceRturn()
			},
			
			//----------钢网用途-------------------
			steelMeshUsageSelect:function(index){
				this.steelMeshUsage = index;
				this.$store.dispatch("setSteelMeshUsage", {
				    steelMeshUsage: index + 1,
				});
				calcStenPriceRturn()
			},
			
			//---------厚度下标---------------
			thicknessIndexSelect:function(index){
				this.thicknessIndex = index;
				this.$store.dispatch("setThicknessIndex", {
				    thicknessIndex: index + 1,
				});
				calcStenPriceRturn()
			},
			
			//-------------抛光工艺---------------
			polishProcessIndexSelect:function(index){
				this.polishProcessIndex = index;
				this.$store.dispatch("setPolishProcessIndex", {
				    polishProcessIndex: index + 1,
				});
				calcStenPriceRturn()
			}
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;
		
	}
	
	@media only screen and (min-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.be_content{
			width: 85%;
			
			.be_ct_select{
				width: 450px;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.be_content{
			width: 85%;
			
			.be_ct_select{
				width: 450px;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.be_content{
			width: 85%;
			
			.be_ct_select{
				width: 450px;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.be_content{
			width: 85%;
			
			.be_ct_select{
				width: 450px;
			}
		}
		
		.input1{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 5vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.be_content{
			width: 85%;
			
			.be_ct_select{
				width: 450px;
			}
		}
		
		.input1{
			width: 10vw;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 10vw;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title{
			display: flex;
			// align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 12px;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 10px;
			}
		}
		
		.be_content{
			width: 80%;
			
			.be_ct_select{
				width: 200px;
			}
		}
		
		.input1{
			width: 10vw;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.x{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.input2{
			width: 10vw;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
</style>