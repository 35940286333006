<template>
	<div>
		<div class="bom" v-if="windowWidth>=1200">
			<div class="bo_left">
				<FileUpload :windowWidth="windowWidth" :windowHeight="windowHeight"></FileUpload>
				<div class="bo_le_titleDiv">
					<div class="bo_le_td_title">{{$t('valuation.fileUpload.title2')}}</div>
					<div class="bo_le_td_text">{{$t('valuation.fileUpload.title3')}}</div>
				</div>
				<div class="bo_le_contentDiv">
					<!-- 采购数量 -->
					<div style="display: flex;">
						<div class="be_title">
							<div class="be_text">{{$t('valuation.fileUpload.title4')}}：</div>
						</div>
						<div style="width: 85%;">
							<a-radio-group v-model="bomQuantityIndex">
								<a-radio :style="radioStyle" :value="1">
									{{$t('valuation.fileUpload.text4')}}
									<input class="input1" type="text" v-model="bomQuantity" />
								</a-radio>
								<a-radio :style="radioStyle" :value="2">
									{{$t('valuation.fileUpload.text5')}}
								</a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<!-- 采购备注 -->
					<div style="display: flex;">
						<div class="be_title">
							<div class="be_text">{{$t('valuation.fileUpload.title5')}}：</div>
						</div>
						<div style="width: 85%;">
							<el-input
							  type="textarea"
							  :rows="2"
							  placeholder="" v-model="purchasemark">
							</el-input>
						</div>
					</div>
				</div>
				
				<div class="bo_le_titleDiv">
					<div class="bo_le_td_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="bo_le_td_text">{{$t('valuation.pcb.text5')}}</div>
				</div>
				
				<order-information style="border: 1px solid #f2f2f2;margin-bottom: 20px;" :dataList="dataList"></order-information>
			</div>
			
			<div class="bo_right">
				<div class="bo_ri_contentDiv">
					<div class="bo_ri_cd_title">{{$t('valuation.quotation.title')}}</div>
					<!-- <div style="padding-top: 0.5vw;font-size: 0.5vw;">快递费：￥0.00</div> -->
					<!-- <div class="" style="padding-top: 1vw;font-size: 0.5vw;padding-bottom: 0.5vw;">
						快递费：￥"0.00"</div>
					<div style="padding-top: 0.5vw;font-size: 0.5vw;padding-bottom: 0.5vw;border-bottom: 1px solid #e8eaeb;">
						BOM价格：￥"0.00"</div> -->
		
					<div class="bo_ri_cd_priceDiv">
						{{$t('valuation.quotation.text13')}}:
						<div class="bo_ri_cd_pd_text">
							{{$t('valuation.fileUpload.price')}}</div>
					</div>
					<div class="bo_ri_cd_buttonDiv">
						<!-- <div class="bo_ri_cd_bd_text" @click="dataInfo">{{$t('valuation.quotation.button')}}</div> -->
						<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
							@click.native.prevent="dataInfo">
							<span v-if="!loading">{{$t('valuation.quotation.button')}}</span>
							<span v-else>{{$t('valuation.quotation.button')}}...</span>
						</el-button>
					</div>
				</div>
			</div>
		</div>
		
		<div class="bom2" v-if="windowWidth<=1199">
			<div class="bo_left">
				<FileUpload :windowWidth="windowWidth" :windowHeight="windowHeight"></FileUpload>
				<div class="bo_le_titleDiv">
					<div class="bo_le_td_title">{{$t('valuation.fileUpload.title2')}}</div>
					<div class="bo_le_td_text">{{$t('valuation.fileUpload.title3')}}</div>
				</div>
				<div class="bo_le_contentDiv">
					<!-- 采购数量 -->
					<div style="display: flex;">
						<div class="be_title">
							<div class="be_text">{{$t('valuation.fileUpload.title4')}}：</div>
						</div>
						<div style="width: 85%;">
							<a-radio-group v-model="bomQuantityIndex">
								<a-radio :style="radioStyle" :value="1">
									{{$t('valuation.fileUpload.text4')}}
									<input class="input1" type="text" v-model="bomQuantity" />
								</a-radio>
								<a-radio :style="radioStyle" :value="2">
									{{$t('valuation.fileUpload.text5')}}
								</a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<!-- 采购备注 -->
					<div style="display: flex;">
						<div class="be_title">
							<div class="be_text">{{$t('valuation.fileUpload.title5')}}：</div>
						</div>
						<div style="width: 85%;">
							<el-input
							  type="textarea"
							  :rows="2"
							  placeholder="" v-model="purchasemark">
							</el-input>
						</div>
					</div>
				</div>
				
				<div class="bo_le_titleDiv">
					<div class="bo_le_td_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="bo_le_td_text">{{$t('valuation.pcb.text5')}}</div>
				</div>
				
				<order-information style="border: 1px solid #f2f2f2;margin-bottom: 20px;" :dataList="dataList"></order-information>
			</div>
			
			<div style="background: #fff;box-shadow: 0 15px 30px  rgba(0, 0, 0, .4);padding: 0 20px;position: fixed;bottom: 0;width: 100%;left: 0;display: flex;align-items: center;height: 60px;">
				<div style="padding-top:10px;font-size: 16px;padding-bottom: 10px;display: flex;line-height: 2vw;">
					{{$t('valuation.quotation.text13')}}:
					<div style="font-size: 2vw;color: red;font-weight: bold;padding-left: 0.5vw;">
						{{$t('valuation.fileUpload.price')}}</div>
						
					<!-- <div style="padding-left: 20px;color: #409EFF;" @click="drawerClick">{{$i18n.locale=="en" ? 'Quotation Details' : '报价明细'}}</div> -->
				</div>
				
				<div style="position: absolute;right: 0;background: #d1102d;padding: 0 40px;bottom: 0;color: #fff;height: 60px;display: flex;align-items: center;" @click="dataInfo">{{$t('valuation.quotation.button')}}</div>
			</div>
		</div>
		
		<login-model :open="loginOpen" @ok="submitForm" @close="close"></login-model>
	</div>
</template>

<script>
	import {
		dataInJs
	} from './data.js';
	import {
		calcPcbPriceReturn
	} from './saveOrder.js'
	import Cookies from "js-cookie";
	import FileUpload from '@/components/FileUpload/index.vue';
	import orderInformation from './selectForm/orderInformation.vue'
	import loginModel from './loginModel.vue'
	import {
		getMyAddList
	} from '@/api/userInfo/index'
	import {
		Notification,
		MessageBox,
		Message,
	} from 'element-ui'
	import {
		saveOrder,
		savePcb,
		clientLand
	} from '../../../api/home/index.js'
	import {
		saveOrderReturn
	} from './submit.js'
	export default {
		components: {
			FileUpload,
			orderInformation,
			loginModel
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				dataList: null, //list数据
				shippingMethod: 0, //出货方式
				calcPcbPriceData: null, //获取计价数据
				userInfo: null,
				radioStyle: {
					display: 'block',
					height: '40px',
					lineHeight: '40px',
				},
				loading:false,
				bomQuantityIndex:1,//bom采购数量下标
				bomQuantity:null,//bom采购数量
				purchasemark:null,//采购备注
				loginOpen:false,
				c: false,
			}
		},

		created() {
			calcPcbPriceReturn()
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.calcPcbPriceData = this.$store.state.selectForm.calcPcbPriceData
			this.bomQuantityIndex = this.$store.state.selectForm.bomQuantityIndex
			if(this.bomQuantityIndex==1){
				this.bomQuantity = this.$store.state.selectForm.bomQuantity
			}
			this.purchasemark = this.$store.state.selectForm.purchasemark
			this.shippingAddressList = this.$store.state.selectForm.shippingAddressList
			this.dataList = this.$store.state.selectForm.dataInfoContent
			// console.log(this.shippingAddressList)
		},

		mounted() {

		},

		watch: {
			"$store.state.selectForm.calcPcbPriceData"(val) {
				this.calcPcbPriceData = val
			},
			
			bomQuantityIndex(val){
				// console.log(this.$store.state.selectForm.bomQuantity)
				// console.log(this.$store.state.selectForm.xlsxDataList)
				this.bomQuantity = this.$store.state.selectForm.bomQuantity
				this.$store.dispatch("setBomQuantityIndex", {
				    bomQuantityIndex: val,
				});
			},
			
			bomQuantity(val){
				this.$store.dispatch("setBomQuantity", {
				    bomQuantity: val,
				});
			},
			
			purchasemark(val){
				this.$store.dispatch("setPurchasemark", {
				    purchasemark: val,
				});
			},
			
			// shippingAddressList1(val){
			// 	this.shippingAddressList = val
			// 	console.log("1111",this.shippingAddressList)
			// }
		},

		computed: {
			// shippingAddressList1(){
			// 	return this.$store.state.selectForm.shippingAddressList;
			// }
		},

		methods: {
			dataInfo: function() {
				this.loading=true;
				if(this.userInfo!==undefined){
					// 地址接口传参
					let queryParam ={
						pageNum: 1,
						pageSize: 50,
						uid: this.userInfo.id,
						isdelete:0
					}
					getMyAddList(queryParam).then(res=>{
						if(res.data.content.length>0){
							saveOrderReturn(4,this.calcPcbPriceData)
							this.loading=false;
						}else{
							this.$message.error('请新增订单地址~')
							this.loading=false;
						}
					})
				}else{
					this.loginOpen=true;
				}
			},
			
			submitForm:function(e){
				clientLand(e).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						Cookies.set("userInfo2", JSON.stringify(res.data), {
							expires: 30
						});
						Cookies.set("role2", res.role, {
							expires: 30
						});
						this.userInfo = Cookies.get("userInfo2")
						if(this.userInfo){
							this.userInfo = JSON.parse(this.userInfo)
						}
						this.dataInfo();
						location.reload();
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			close:function(){
				this.loginOpen=false;
			}
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;
	}
	
	@media only screen and (min-width:1920px) {
		.bom{
			display: flex;
			width: 100%;
			padding: 0 200px;
			
			.bo_left{
				width: 70%;
				
				.bo_le_titleDiv{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.bo_le_td_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.bo_le_td_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
				
				.bo_le_contentDiv{
					padding: 1vw;
					border: 1px solid #f2f2f2;
				}
			}
			
			.bo_right{
				width: 30%;
				
				.bo_ri_contentDiv{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.bo_ri_cd_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.bo_ri_cd_priceDiv{
						padding-top: 1vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.bo_ri_cd_pd_text{
							font-size: 1.5vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.bo_ri_cd_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.bo_ri_cd_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				padding-top: 10px;
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 5vw;
			margin-left: 10px;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.bom{
			display: flex;
			width: 100%;
			padding: 0 200px;
			
			.bo_left{
				width: 70%;
				
				.bo_le_titleDiv{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.bo_le_td_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.bo_le_td_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
				
				.bo_le_contentDiv{
					padding: 1vw;
					border: 1px solid #f2f2f2;
				}
			}
			
			.bo_right{
				width: 30%;
				
				.bo_ri_contentDiv{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.bo_ri_cd_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.bo_ri_cd_priceDiv{
						padding-top: 1vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.bo_ri_cd_pd_text{
							font-size: 1.5vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.bo_ri_cd_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.bo_ri_cd_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				padding-top: 10px;
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 5vw;
			margin-left: 10px;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.bom{
			display: flex;
			width: 100%;
			padding: 0 140px;
			
			.bo_left{
				width: 70%;
				
				.bo_le_titleDiv{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.bo_le_td_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.bo_le_td_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
				
				.bo_le_contentDiv{
					padding: 1vw;
					border: 1px solid #f2f2f2;
				}
			}
			
			.bo_right{
				width: 30%;
				
				.bo_ri_contentDiv{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.bo_ri_cd_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.bo_ri_cd_priceDiv{
						padding-top: 1vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.bo_ri_cd_pd_text{
							font-size: 1.5vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.bo_ri_cd_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.bo_ri_cd_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				padding-top: 10px;
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 5vw;
			margin-left: 10px;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.bom{
			display: flex;
			width: 100%;
			padding: 0 30px;
			
			.bo_left{
				width: 70%;
				
				.bo_le_titleDiv{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.bo_le_td_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.bo_le_td_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
				
				.bo_le_contentDiv{
					padding: 1vw;
					border: 1px solid #f2f2f2;
				}
			}
			
			.bo_right{
				width: 30%;
				
				.bo_ri_contentDiv{
					padding: 20px;
					border: 1px solid #e8eaeb;
					margin-left: 20px;
					
					.bo_ri_cd_title{
						font-weight: bold;
						font-size: 1vw;
					}
					
					.bo_ri_cd_priceDiv{
						padding-top: 1vw;
						font-size: 0.5vw;
						padding-bottom: 0.5vw;
						display: flex;
						line-height: 2vw;
						
						.bo_ri_cd_pd_text{
							font-size: 1.5vw;
							color: red;
							font-weight: bold;
							padding-left: 0.5vw;
						}
					}
					
					.bo_ri_cd_buttonDiv{
						position: relative;
						padding-top:20px;
						text-align: right;
						z-index: 1;
						
						.bo_ri_cd_bd_text{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				padding-top: 10px;
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 5vw;
			margin-left: 10px;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.bom2{
			display: flex;
			width: 100%;
			padding: 0 30px;
			
			.bo_left{
				width: 100%;
				
				.bo_le_titleDiv{
					padding: 1vw;
					align-items: center;
					background: #f8f8f8;
					
					.bo_le_td_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.bo_le_td_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
				}
				
				.bo_le_contentDiv{
					padding: 1vw;
					border: 1px solid #f2f2f2;
				}
			}
			
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				padding-top: 10px;
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 10vw;
			margin-left: 10px;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:767px) {
		.bom2{
			display: flex;
			width: 100%;
			padding: 0 30px;
			
			.bo_left{
				width: 100%;
				
				.bo_le_titleDiv{
					padding: 10px;
					align-items: center;
					background: #f8f8f8;
					
					.bo_le_td_title{
						font-size: 12px;
						font-weight: bold;
					}
					
					.bo_le_td_text{
						font-size: 10px;
						color: #888;
						padding-top: 5px;
					}
				}
				
				.bo_le_contentDiv{
					padding: 1vw;
					border: 1px solid #f2f2f2;
				}
			}
			
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 30%;
		
			.be_mandatory {
				font-size: 12px;
				color: red;
			}
		
			.be_text {
				padding-top: 10px;
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1 {
			width: 10vw;
			margin-left: 10px;
			height: 23px;
			line-height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
</style>
