<template>
	<div>
		<div class="fileUpload" :style="xlsxName==null ? '' : 'border-bottom: 2px solid #d1102d;'" v-if="windowWidth>=1200">
			<div class="fu_titleDiv">
				<img class="fu_td_img" src="https://koiios-dev.oss-cn-beijing.aliyuncs.com/valuation/bom_title%40ico.png" />
				<span class="fu_td_text" @click="handleReport"> {{$t('valuation.fileUpload.title1')}}</span>
				<a-table :columns="columnsDetails" :data-source="pcbInfoList" id="outTable" rowKey="id" v-show="hide==true"
					:scroll="{ x: 1300 }"></a-table>
				<el-link type="primary" @click="tempDownload">下载模板</el-link>
			</div>
			<el-upload ref="targetUpload" v-if="xlsxName==null" style="width: 100%;position: relative;" action="#" :auto-upload="false" :on-change="onChange" :limit="1"
				:show-file-list="false" accept=".xls,.xlsx,.csv" multiple>
				  <div class="bomUpload">
				  		<div class="bu_title">
							<i class="el-icon-upload bu_ti_icon"></i>
							<span class="bu_ti_text">{{$t('valuation.fileUpload.text1')}}</span>
						</div>
						<div class="bu_text1">{{$t('valuation.fileUpload.text2')}}</div>
						<div class="bu_text2">{{$t('valuation.fileUpload.text3')}}</div>
				  </div>
			</el-upload>
			<div class="uploadSuccess" v-else>
				<img class="us_icon" src="https://koiios-dev.oss-cn-beijing.aliyuncs.com/valuation/quote-BOM%40ico.png" />
				<div class="us_contentDiv">
					<div class="us_cd_name">{{xlsxName}}</div>
					<div class="us_cd_text">{{$t('valuation.fileUpload.text6')}}{{size / 1024}}MB</div>
				</div>
				<div class="us_buttonDiv">
					<c-button style="padding-left: 20px;" type="danger" @click="deleteXlsx()">{{$t('valuation.fileUpload.button1')}}</c-button>
					<c-button style="padding-left: 20px;" type="primary" @click="selectXlsx()">{{$t('valuation.fileUpload.button2')}}</c-button>
				</div>
			</div>
		</div>
		
		<div class="fileUpload" :style="xlsxName==null ? '' : 'border-bottom: 2px solid #d1102d;'" v-if="windowWidth<=1199">
			<div class="fu_titleDiv">
				<img class="fu_td_img" src="https://koiios-dev.oss-cn-beijing.aliyuncs.com/valuation/bom_title%40ico.png" />
				<span class="fu_td_text" @click="handleReport"> {{$t('valuation.fileUpload.title1')}}</span>
				<a-table :columns="columnsDetails" :data-source="pcbInfoList" id="outTable" rowKey="id" v-show="hide==true"
					:scroll="{ x: 1300 }"></a-table>
				<!-- <el-link type="primary" @click="tempDownload">下载模板</el-link> -->
			</div>
			<el-upload ref="targetUpload" v-if="xlsxName==null" style="width: 100%;position: relative;" action="#" :auto-upload="false" :on-change="onChange" :limit="1"
				:show-file-list="false" accept=".xls,.xlsx,.csv" multiple>
				  <div class="bomUpload">
				  		<div class="bu_title">
				  			<i class="el-icon-upload bu_ti_icon"></i>
				  			<span class="bu_ti_text">{{$t('valuation.fileUpload.text1')}}</span>
				  		</div>
				  		<div class="bu_text1">{{$t('valuation.fileUpload.text2')}}</div>
				  		<div class="bu_text2">{{$t('valuation.fileUpload.text3')}}</div>
				  </div>
			</el-upload>
			<div class="uploadSuccess2" v-else>
				<img class="us_icon" src="https://koiios-dev.oss-cn-beijing.aliyuncs.com/valuation/quote-BOM%40ico.png" />
				<div class="us_contentDiv">
					<div class="us_cd_name">{{xlsxName}}</div>
					<div class="us_cd_text">{{$t('valuation.fileUpload.text6')}}{{size / 1024}}MB</div>
				</div>
				<div class="us_buttonDiv">
					<c-button style="padding-left: 20px;" type="danger" @click="deleteXlsx()">{{$t('valuation.fileUpload.button1')}}</c-button>
					<c-button style="padding-left: 20px;" type="primary" @click="selectXlsx()">{{$t('valuation.fileUpload.button2')}}</c-button>
				</div>
			</div>
		</div>
		<xlsx-upload-model :open="open" :defaultPlainOptions="plainOptions" :bomForm="form" :dataList="dataList" @close="cancel" @ok="submitForm"></xlsx-upload-model>
	</div>
</template>

<script>
	const xlsx = require("xlsx");
	import CButton from '@/components/CButton/index.vue' //button组件引入
	import xlsxUploadModel from './components/xlsxUploadModel.vue'
	import FileSaver from "file-saver";
	const XLSX = require("xlsx");
	export default {
		components:{
			CButton,
			xlsxUploadModel
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				xlsxName:null,//文件名
				size:null,//文件大小
				open:false,//是否打开弹框
				plainOptions:null,//表头
				xlsxDataList:[],//xlsx列表
				columns:[],//表格第一行参数
				dataList:[],//xlsx内容
				bomList:[],//bom表单数据
				form: null,
				columnsDetails: [{
					title: '排序号',
					dataIndex: 'id',
					width: 150,
					key:'排序号',
				}, {
					title: '完整型号',
					dataIndex: 'bomtype',
					width: 150,
					key:'完整型号',
				}, {
					title: '品牌',
					dataIndex: 'bombrand',
					width: 150,
					key:'品牌',
				}, {
					title: '封装',
					dataIndex: 'bompack',
					width: 150,
					key:'封装',
				}, {
					title: '参数',
					dataIndex: 'bomparam',
					width: 150,
					key:'参数',
				}, {
					title: '数量',
					dataIndex: 'bomamount',
					width: 150,
					key:'数量',
				}, {
					title: '含税单价',
					dataIndex: 'bomtaxprice',
					width: 150,
					key:'含税单价',
				}, {
					title: '年份',
					dataIndex: 'bomyear',
					width: 150,
					key:'年份',
				}, {
					title: '备注',
					dataIndex: 'bommark',
					width: 150,
					key:'备注',
				}],
				pcbInfoList:[],
				hide:false,//是否显示表单
			}
		},
		created() {
			this.xlsxName = this.$store.state.selectForm.xlsxName;
			this.size = this.$store.state.selectForm.bomSize;
			this.plainOptions = this.$store.state.selectForm.plainOptions;
			this.xlsxDataList = this.$store.state.selectForm.xlsxDataList;
			this.dataList = this.$store.state.selectForm.bomDataList;
			this.columns = this.$store.state.selectForm.columns;
		},
		activated(){
			this.xlsxName = this.$store.state.selectForm.xlsxName;
			this.size = this.$store.state.selectForm.bomSize;
			this.plainOptions = this.$store.state.selectForm.plainOptions;
			this.xlsxDataList = this.$store.state.selectForm.xlsxDataList;
			this.dataList = this.$store.state.selectForm.bomDataList;
			this.columns = this.$store.state.selectForm.columns;
		},
		methods: {
			async onChange(file) {
				this.$refs.targetUpload.clearFiles()
				/**
				 * 1. 使用原生api去读取好的文件
				 * */
				// console.log("原始上传的文件", file);
				this.xlsxName = file.name;
				this.size = file.size;
				// 读取文件不是立马能够读取到的，所以是异步的，使用Promise
				let dataBinary = await new Promise((resolve) => {
					// Web API构造函数FileReader，可实例化对象，去调用其身上方法，去读取解析文件信息
					let reader = new FileReader(); // https://developer.mozilla.org/zh-CN/docs/Web/API/FileReader
					// console.log("实例化对象有各种方法", reader);
					reader.readAsBinaryString(file.raw); // 读取raw的File文件
					reader.onload = (ev) => {
						// console.log("文件解析流程进度事件", ev);
						resolve(ev.target.result); // 将解析好的结果扔出去，以供使用
					};
				});
				// console.log("读取出的流文件", dataBinary);

				/**
				 * 2. 使用xlsx插件去解析已经读取好的二进制excel流文件
				  * */
				let workBook = xlsx.read(dataBinary, {
					type: "binary",
					cellDates: true
				});
				// excel中有很多的sheet，这里取了第一个sheet：workBook.SheetNames[0]
				let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]];
				// 分为第一行的数据，和第一行下方的数据
				const header = this.getHeaderRow(firstWorkSheet);
				// console.log("读取的excel表头数据（第一行）", header);
				const data = xlsx.utils.sheet_to_json(firstWorkSheet);
				// console.log("读取所有excel数据", data);
				// console.log("读取所有excel数据", data[1]. ['参数']);
				this.xlsxDataInfo(file,header,data);
			},
			
			getHeaderRow(sheet) {
				const headers = []; // 定义数组，用于存放解析好的数据
				const range = xlsx.utils.decode_range(sheet["!ref"]); // 读取sheet的单元格数据
				let C;
				const R = range.s.r;
				/* start in the first row */
				for (C = range.s.c; C <= range.e.c; ++C) {
					/* walk every column in the range */
					const cell = sheet[xlsx.utils.encode_cell({
						c: C,
						r: R
					})];
					/* find the cell in the first row */
					let hdr = "UNKNOWN " + C; // <-- replace with your desired default
					if (cell && cell.t) hdr = xlsx.utils.format_cell(cell);
					headers.push(hdr);
				}
				return headers; // 经过上方一波操作遍历，得到最终的第一行头数据
			},
			
			//------------xlsx表格数据---------------------------------
			xlsxDataInfo:function(file,header,data){
				this.open = true;
				this.xlsxDataList = [];
				// console.log("读取的excel表头数据（第一行）", header);
				this.plainOptions = header;
				this.dataList=data
				this.$store.dispatch("setXlsxName", {
				    xlsxName: this.xlsxName,
				});
				this.$store.dispatch("setBomSize", {
				    bomSize: this.size,
				});
				this.$store.dispatch("setPlainOptions", {
				    plainOptions: this.plainOptions,
				});
				this.$store.dispatch("setBomDataList", {
				    bomDataList: this.dataList,
				});
			},
			
			//--------------点击导入------------------------------------
			handleReport() {
			  this.$refs["targetUpload"].$refs["upload-inner"].handleClick()
			},
			
			//---------------选择属性弹框打开--------------------
			selectXlsx:function(){
				this.open=true;
				// console.log(this.$store.state.selectForm.bomForm)
			},
			
			//----------------删除-----------------------
			deleteXlsx:function(){
				this.$store.dispatch("setXlsxName", {
				    xlsxName: null,
				});
				this.$store.dispatch("setBomSize", {
				    bomSize: null,
				});
				this.$store.dispatch("setPlainOptions", {
				    plainOptions: null,
				});
				this.$store.dispatch("setXlsxDataList", {
				    xlsxDataList: [],
				});
				this.$store.dispatch("setBomDataList", {
				    bomDataList: [],
				});
				this.$store.dispatch("setBomForm", {
				    bomForm: null,
				});
				this.xlsxName = null;
				this.size = null;
				this.plainOptions = null;
				this.xlsxDataList = [];
				this.dataList = [];
				this.form = null;
			},
			
			//---------------关闭弹框------------------------------
			cancel:function(){
				this.open=false;
			},
			
			//----------------保存属性--------------------------
			submitForm:function(e,data,form){
				this.bomList=[];
				this.open=false;
				this.form=form;
				this.$store.dispatch("setBomForm", {
				    bomForm: e,
				});
				this.$store.dispatch("setXlsxDataList", {
				    xlsxDataList: data,
				});
				// console.log("保存属性",e)
			},
			
			//------------------下载模板----------------------
			tempDownload:function(){
				var xlsxParam = {
					raw: true
				}; //转换成excel时，使用原始的格式
				var wb = XLSX.utils.table_to_book(document.querySelector("#outTable"), xlsxParam); //outTable为列表id
				var wbout = XLSX.write(wb, {
					bookType: "xlsx",
					bookSST: true,
					type: "array"
				});
				try {
					FileSaver.saveAs(
						new Blob([wbout], {
							type: "application/octet-stream;charset=utf-8"
						}),
						"BOM配单.xlsx"
					);
				} catch (e) {
					if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout;
			}
		},
	};
</script>

<style lang="less" scoped>
	.el-icon-upload{
		color: #d1102d;
	}
	
	/deep/ .el-upload{
		width: 100%;
	}
	
	@media only screen and (min-width:1920px) {
		.fileUpload{
			border: 1px solid #e8eaeb;
			
			.fu_titleDiv{
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;
				
				.fu_td_img{
					width: 20px;
					height: 20px;
				}
				
				.fu_td_text{
					padding-left: 10px;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
		
		.bomUpload{
			width: calc(100% - 40px);
			padding: 40px 20px;
			background: #f8f8f8;
			left: 0;
			margin: 20px;
			border: 2px dashed #d8d8d8;
			top: 0;
			
			.bu_title{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.bu_ti_icon{
					font-size: 40px;
				}
				
				.bu_ti_text{
					font-size: 20px;
				}
			}
			
			.bu_text1{
				font-size: 20px;
				padding-top: 10px;
			}
			
			.bu_text2{
				font-size: 14px;
				padding-top: 5px;
				color: #999999;
			}
		}
		
		.bomUpload:hover{
			background: #efefef;
		}
		
		.uploadSuccess{
			padding-left: 20px;
			padding-top: 20px;
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			
			.us_icon{
				width: 50px;
				height: 50px;
				padding: 5px;
				border: 1px solid #f2f2f2;
			}
			
			.us_contentDiv{
				padding-left: 10px;
				
				.us_cd_name{
					font-size: 20px;
				}
				
				.us_cd_text{
					color: #999999;
				}
			}
			
			.us_buttonDiv{
				padding-left: 30px;
				display: flex;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.fileUpload{
			border: 1px solid #e8eaeb;
			
			.fu_titleDiv{
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;
				
				.fu_td_img{
					width: 20px;
					height: 20px;
				}
				
				.fu_td_text{
					padding-left: 10px;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
		
		.bomUpload{
			width: calc(100% - 40px);
			padding: 40px 20px;
			background: #f8f8f8;
			left: 0;
			margin: 20px;
			border: 2px dashed #d8d8d8;
			top: 0;
			
			.bu_title{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.bu_ti_icon{
					font-size: 40px;
				}
				
				.bu_ti_text{
					font-size: 20px;
				}
			}
			
			.bu_text1{
				font-size: 20px;
				padding-top: 10px;
			}
			
			.bu_text2{
				font-size: 14px;
				padding-top: 5px;
				color: #999999;
			}
		}
		
		.bomUpload:hover{
			background: #efefef;
		}
		
		.uploadSuccess{
			padding-left: 20px;
			padding-top: 20px;
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			
			.us_icon{
				width: 50px;
				height: 50px;
				padding: 5px;
				border: 1px solid #f2f2f2;
			}
			
			.us_contentDiv{
				padding-left: 10px;
				
				.us_cd_name{
					font-size: 20px;
				}
				
				.us_cd_text{
					color: #999999;
				}
			}
			
			.us_buttonDiv{
				padding-left: 30px;
				display: flex;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.fileUpload{
			border: 1px solid #e8eaeb;
			
			.fu_titleDiv{
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;
				
				.fu_td_img{
					width: 20px;
					height: 20px;
				}
				
				.fu_td_text{
					padding-left: 10px;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
		
		.bomUpload{
			width: calc(100% - 40px);
			padding: 40px 20px;
			background: #f8f8f8;
			left: 0;
			margin: 20px;
			border: 2px dashed #d8d8d8;
			top: 0;
			
			.bu_title{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.bu_ti_icon{
					font-size: 40px;
				}
				
				.bu_ti_text{
					font-size: 20px;
				}
			}
			
			.bu_text1{
				font-size: 20px;
				padding-top: 10px;
			}
			
			.bu_text2{
				font-size: 14px;
				padding-top: 5px;
				color: #999999;
			}
		}
		
		.bomUpload:hover{
			background: #efefef;
		}
		
		.uploadSuccess{
			padding-left: 20px;
			padding-top: 20px;
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			
			.us_icon{
				width: 50px;
				height: 50px;
				padding: 5px;
				border: 1px solid #f2f2f2;
			}
			
			.us_contentDiv{
				padding-left: 10px;
				
				.us_cd_name{
					font-size: 20px;
				}
				
				.us_cd_text{
					color: #999999;
				}
			}
			
			.us_buttonDiv{
				padding-left: 30px;
				display: flex;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.fileUpload{
			border: 1px solid #e8eaeb;
			
			.fu_titleDiv{
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;
				
				.fu_td_img{
					width: 20px;
					height: 20px;
				}
				
				.fu_td_text{
					padding-left: 10px;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
		
		.bomUpload{
			width: calc(100% - 40px);
			padding: 40px 20px;
			background: #f8f8f8;
			left: 0;
			margin: 20px;
			border: 2px dashed #d8d8d8;
			top: 0;
			
			.bu_title{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.bu_ti_icon{
					font-size: 40px;
				}
				
				.bu_ti_text{
					font-size: 20px;
				}
			}
			
			.bu_text1{
				font-size: 20px;
				padding-top: 10px;
			}
			
			.bu_text2{
				font-size: 14px;
				padding-top: 5px;
				color: #999999;
			}
		}
		
		.bomUpload:hover{
			background: #efefef;
		}
		
		.uploadSuccess{
			padding-left: 20px;
			padding-top: 20px;
			display: flex;
			align-items: center;
			padding-bottom: 20px;
			
			.us_icon{
				width: 50px;
				height: 50px;
				padding: 5px;
				border: 1px solid #f2f2f2;
			}
			
			.us_contentDiv{
				padding-left: 10px;
				
				.us_cd_name{
					font-size: 20px;
				}
				
				.us_cd_text{
					color: #999999;
				}
			}
			
			.us_buttonDiv{
				padding-left: 30px;
				display: flex;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.fileUpload{
			border: 1px solid #e8eaeb;
			
			.fu_titleDiv{
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;
				
				.fu_td_img{
					width: 20px;
					height: 20px;
				}
				
				.fu_td_text{
					padding-left: 10px;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
		
		.bomUpload{
			width: calc(100% - 40px);
			padding: 40px 20px;
			background: #f8f8f8;
			left: 0;
			margin: 20px;
			border: 2px dashed #d8d8d8;
			top: 0;
			
			.bu_title{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.bu_ti_icon{
					font-size: 40px;
				}
				
				.bu_ti_text{
					font-size: 20px;
				}
			}
			
			.bu_text1{
				font-size: 20px;
				padding-top: 10px;
			}
			
			.bu_text2{
				font-size: 14px;
				padding-top: 5px;
				color: #999999;
			}
		}
		
		.bomUpload:hover{
			background: #efefef;
		}
		
		.uploadSuccess2{
			padding-left: 20px;
			padding-top: 20px;
			padding-bottom: 20px;
			text-align: center;
			
			.us_icon{
				width: 50px;
				height: 50px;
				padding: 5px;
				border: 1px solid #f2f2f2;
			}
			
			.us_contentDiv{
				padding-left: 10px;
				
				.us_cd_name{
					font-size: 20px;
				}
				
				.us_cd_text{
					color: #999999;
				}
			}
			
			.us_buttonDiv{
				display: flex;
				padding-top: 20px;
				justify-content: center;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.fileUpload{
			border: 1px solid #e8eaeb;
			
			.fu_titleDiv{
				padding-top: 20px;
				padding-left: 20px;
				display: flex;
				align-items: center;
				
				.fu_td_img{
					width: 20px;
					height: 20px;
				}
				
				.fu_td_text{
					padding-left: 10px;
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
		
		.bomUpload{
			width: calc(100% - 40px);
			padding: 40px 20px;
			background: #f8f8f8;
			left: 0;
			margin: 20px;
			border: 2px dashed #d8d8d8;
			top: 0;
			
			.bu_title{
				display: flex;
				align-items: center;
				justify-content: center;
				
				.bu_ti_icon{
					font-size: 40px;
				}
				
				.bu_ti_text{
					font-size: 20px;
				}
			}
			
			.bu_text1{
				font-size: 20px;
				padding-top: 10px;
			}
			
			.bu_text2{
				font-size: 14px;
				padding-top: 5px;
				color: #999999;
			}
		}
		
		.bomUpload:hover{
			background: #efefef;
		}
		
		.uploadSuccess2{
			padding-left: 20px;
			padding-top: 20px;
			padding-bottom: 20px;
			text-align: center;
			
			.us_icon{
				width: 50px;
				height: 50px;
				padding: 5px;
				border: 1px solid #f2f2f2;
			}
			
			.us_contentDiv{
				padding-left: 10px;
				
				.us_cd_name{
					font-size: 20px;
				}
				
				.us_cd_text{
					color: #999999;
				}
			}
			
			.us_buttonDiv{
				display: flex;
				padding-top: 20px;
				justify-content: center;
			}
		}
	}
	
</style>