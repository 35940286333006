<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="580" :modalWidth="windowWidth>=1200 ? '640' : '300'"
			@cancel="cancel">
			<!-- 不可选填 -->
			<a-form-model ref="form" :model="form" :rules="rules" slot="content" layout="vertical">
				<a-row class="form-row" :gutter="32">
					<a-col :lg="24" :md="24" :sm="24">
						<div style="padding-top: 20px;">
							<a-table :columns="columns" :data-source="dataList" :pagination="false" :rowKey="(row, index) => {return index;}" :scroll="{ x: 1500,y:280 }">
								<template v-for="(item,index) in columns" v-if :slot="item.scopedSlots.filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
									<div :key="index" style="padding: 8px;">
										<!-- <a-select v-model="item.value" style="width: 188px; margin-bottom: 8px; display: block;" allowClear placeholder="请选择对应完整型号列名" @change="onChange($event,item,confirm)">
										    <a-select-option v-for="item in plainOptions" :key="item" :value="item">{{ item }}</a-select-option>
										</a-select> -->
										<ul class="aTableUl">
										    <li class="atu_li" v-for="item2 in plainOptions" :key="item2" @click="filterDropdownClick(item2,index,confirm)">
										    	{{item2}}
										    </li>
										</ul>
									</div>
								</template>
							</a-table>
						</div>
					</a-col>
				</a-row>
			</a-form-model>
			<template slot="footer">
				<a-button type="primary" @click="submitForm">
					保存
				</a-button>
				<!-- <a-button @click="cancel">
			    取消
				</a-button> -->
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import xlsxUploadModel from './xlsxUploadModel.js'
	export default {
		...xlsxUploadModel
	}
</script>

<style lang="less" scoped>
/deep/.ant-table-body{
    min-height: 18px;
  }
  
  /deep/.ant-table-fixed{
	  margin-right: 20px;
  }
  
  .aTableUl{
	  overflow:auto;
	  height:200px;
	  
	  .atu_li{
		  cursor: pointer;
		  padding:10px 20px;
	  }
	  
	  .atu_li:hover{
		  background: rgba(209, 16, 45, 0.1);
		  color: #d1102d;
	  }
  }
</style>