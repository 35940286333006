<template>
	<div class="boardElement">
		<div style="position: relative;">
			<div style="display: flex;">
				<div class="be_title">
					<div class="be_mandatory">*</div>
					<div class="be_text">{{$t('valuation.pcb.boardElement.title1')}}：</div>
					<el-popover placement="bottom-start" width="400" trigger="hover"
						:content="$t('valuation.pcb.boardElement.content1')">
						<div slot="reference">
							<i class="el-icon-question"></i>
						</div>
					</el-popover>
				</div>
				<div style="width: 85%;">
					<single-choice :dataIndex="shippingMethod" :dataList="dataList[0]" @select="selectBoxClick"></single-choice>
				</div>
			</div>
			
			<div style="display: flex;padding-top: 20px;">
				<div class="be_title">
					<div class="be_mandatory">*</div>
					<div class="be_text">{{$t('valuation.pcb.boardElement.title2')}}：</div>
					<el-popover placement="bottom-start" width="400" trigger="hover"
						:content="$t('valuation.pcb.boardElement.content2')">
						<div slot="reference">
							<i class="el-icon-question"></i>
						</div>
					</el-popover>
				</div>
				<div style="width: 85%;">
					<div style="display: flex;">
						<input class="input1" type="text" v-model="boardLength" :placeholder="$t('valuation.pcb.boardElement.input1')" />
						<span class="x">x</span>
						<input class="input2" type="text" v-model="boardWidth" :placeholder="$t('valuation.pcb.boardElement.input2')" />
						<span class="cm">cm</span>
					</div>
				</div>
			</div>
			
			<div style="display: flex;padding-top: 20px;">
				<div class="be_title">
					<div class="be_mandatory">*</div>
					<div class="be_text">{{$t('valuation.pcb.boardElement.title3')}}：</div>
					<el-popover placement="bottom-start" width="400" trigger="hover"
						:content="$t('valuation.pcb.boardElement.content3')">
						<div slot="reference">
							<i class="el-icon-question"></i>
						</div>
					</el-popover>
				</div>
				<div style="width: 85%;">
					<div style="display: flex;position: relative;" @mouseover="quantityMoserover" @mouseleave="quantityMouseleave">
						<input class="input1" style="cursor: pointer;" type="text" v-model="boardNumber" placeholder="" :disabled="true" />
						<span class="x">PCS</span>
						<div class="popover" v-if="quantityType==true">
							<el-radio-group v-model="boardNumber">
								<el-radio :label="item.name" v-for="(item,index) in dataList[17]" :key="index" v-if="item.isdele==false" style="padding: 10px 0;">{{item.name}}</el-radio>
							</el-radio-group>
						</div>
					</div>
				</div>
			</div>
			
			<div style="display: flex;justify-content: right;">
				<el-image style="padding: 10px;" :src="require('@/assets/img/min-size.png')"></el-image>
			</div>
		</div>
		
		<!-- <div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">板子面积：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					content="面积一平米内，数量50pcs内为样板，超出此范围则为小批量。">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 85%;">
				<div style="display: flex;">
					<input class="input1" type="text" v-model="boardArea" placeholder="请输入面积" />
				</div>
			</div>
		</div> -->
		
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import { calcPcbPriceReturn } from '../saveOrder.js'
	export default {
		components:{
			singleChoice
		},
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
			// shippingMethod:{
			// 	type:String,
			// 	default: null,
			// }
		},
		data() {
			return {
				shippingMethod:0,//出货方式
				boardLength:'',//板子长度
				boardWidth:'',//板子宽度
				boardNumber:'',//板子数量
				boardArea:'',//板子面积
				quantityType:false,//是否打开数量弹框
			}
		},

		created() {
			this.shippingMethod = this.$store.state.selectForm.shippingMethod;
			this.boardLength = this.$store.state.selectForm.boardLength;
			this.boardWidth = this.$store.state.selectForm.boardWidth;
			this.boardNumber = this.$store.state.selectForm.boardNumber;
			this.boardArea = this.$store.state.selectForm.boardArea;
			// console.log(this.shippingMethod)
		},

		mounted() {
			
		},

		watch: {
			boardLength(val){
				// console.log(val)
				this.$nextTick(()=>{
					this.$store.dispatch("setBoardLength", {
					    boardLength: val,
					});
					calcPcbPriceReturn()
				})
			},
			boardWidth(val){
				// console.log(val)
				this.$nextTick(()=>{
					this.$store.dispatch("setBoardWidth", {
					    boardWidth: val,
					});
					calcPcbPriceReturn()
				})
			},
			boardNumber(val){
				this.$nextTick(()=>{
					this.$store.dispatch("setBoardNumber", {
					    boardNumber: val,
					});
					calcPcbPriceReturn()
				})
			},
			boardArea(val){
				this.$nextTick(()=>{
					this.$store.dispatch("setBoardArea", {
					    boardArea: val,
					});
					calcPcbPriceReturn()
				})
			}
		},

		computed: {

		},

		methods: {
			//--------------------单选/多选框选择------------------------------------
			selectBoxClick:function(id){
				this.shippingMethod=id;
				this.$store.dispatch("setShippingMethod", {
				    shippingMethod: id,
				});
				calcPcbPriceReturn()
			},
			
			//-------------是否打开数量弹框------------------------------
			quantityMoserover:function(){
				this.quantityType=true;
			},
			
			//---------------关闭数量弹框-----------------------------
			quantityMouseleave:function(){
				this.quantityType=false;
			},
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;
		
	}
	
	@media only screen and (min-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		
		.boardElement{
			padding: 1vw;
			background: #f8f8f8;
		}
		
		.be_title{
			display: flex;
			align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 6vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 40px;
			height: 40px;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.popover{
			position: absolute;
			top:32px;
			left: 0;
			width: 400px;
			border: 1px solid #d6d6d6;
			background: #fff;
			padding: 20px;
			z-index: 2;
		}
		
		.input2{
			width: 6vw;
			// height: 2vw;
			// line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		
		.boardElement{
			padding: 1vw;
			background: #f8f8f8;
		}
		
		.be_title{
			display: flex;
			align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 6vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 40px;
			height: 40px;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.popover{
			position: absolute;
			top:32px;
			left: 0;
			width: 400px;
			border: 1px solid #d6d6d6;
			background: #fff;
			padding: 20px;
			z-index: 2;
		}
		
		.input2{
			width: 6vw;
			// height: 2vw;
			// line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		
		.boardElement{
			padding: 1vw;
			background: #f8f8f8;
		}
		
		.be_title{
			display: flex;
			align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 6vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 40px;
			height: 40px;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.popover{
			position: absolute;
			top:32px;
			left: 0;
			width: 400px;
			border: 1px solid #d6d6d6;
			background: #fff;
			padding: 20px;
			z-index: 2;
		}
		
		.input2{
			width: 6vw;
			// height: 2vw;
			// line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		
		.boardElement{
			padding: 1vw;
			background: #f8f8f8;
		}
		
		.be_title{
			display: flex;
			align-items: center;
			width:20%;
			
			.be_mandatory{
				font-size: 1vw;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1{
			width: 6vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 40px;
			height: 40px;
		}
		
		.x{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.popover{
			position: absolute;
			top:32px;
			left: 0;
			width: 400px;
			border: 1px solid #d6d6d6;
			background: #fff;
			padding: 20px;
			z-index: 2;
		}
		
		.input2{
			width: 6vw;
			// height: 2vw;
			// line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 0.8vw;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		
		.boardElement{
			padding: 1vw;
			background: #f8f8f8;
		}
		
		.be_title{
			display: flex;
			align-items: center;
			width:15%;
			
			.be_mandatory{
				font-size: 16px;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 16px;
			}
		}
		
		.input1{
			width: 60px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 23px;
			height: 23px;
		}
		
		.x{
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.popover{
			position: absolute;
			top:32px;
			left: 0;
			width: 400px;
			border: 1px solid #d6d6d6;
			background: #fff;
			padding: 20px;
			z-index: 2;
		}
		
		.input2{
			width: 60px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 23px;
			height: 23px;
		}
		
		.cm{
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		
		.boardElement{
			padding: 1vw;
			background: #f8f8f8;
		}
		
		.be_title{
			display: flex;
			align-items: center;
			width:30%;
			
			.be_mandatory{
				font-size: 10px;
				color: red;
			}
			
			.be_text{
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1{
			width: 60px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
			line-height: 23px;
			height: 23px;
		}
		
		.x{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.popover{
			position: absolute;
			top:32px;
			left: 0;
			width: 400px;
			border: 1px solid #d6d6d6;
			background: #fff;
			padding: 20px;
			z-index: 2;
		}
		
		.input2{
			width: 60px;
			line-height: 23px;
			height: 23px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cm{
			display: inline-block;
			font-size: 10px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
</style>
