<template>
	<div>
		<div class="selectForm" v-if="windowWidth>=1200">
			<div class="sf_div">
				<div class="sf_div_title">
					<div class="sf_div_ti_title">{{$t('valuation.pcb.title1')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text1')}}</div>
				</div>
				<board-element :dataList="dataList"></board-element>
				<board-element-two :dataList="dataList"></board-element-two>
				<div class="sf_div_title" style="background: #f8f8f8;">
					<div class="sf_div_ti_title" style="padding-top: 5px;">{{$t('valuation.pcb.title2')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text2')}}</div>
				</div>
				<individualization :dataList="dataList"></individualization>
		
				<div class="sf_div_title" style="background: #f8f8f8;">
					<div class="sf_div_ti_title">{{$t('valuation.pcb.title3')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text3')}}</div>
					<div class="sf_div_ti_text2">{{$t('valuation.pcb.text4')}}</div>
				</div>
				<special-processes :dataList="dataList"></special-processes>
		
				<div class="sf_div_title" style="background: #f8f8f8;">
					<div class="sf_div_ti_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text5')}}</div>
				</div>
				<order-information :dataList="dataList"></order-information>
			</div>
		
			<div class="right">
				<div class="ri_div">
					<div class="ri_div_text1">{{$t('valuation.quotation.title')}}</div>
					<!-- <div style="padding-top: 0.5vw;font-size: 0.5vw;">快递费：￥0.00</div> -->
					<div class="ri_div_text2">
						{{$t('valuation.quotation.text1')}}：￥{{calcPcbPriceData.engineerfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text2')}}：￥{{calcPcbPriceData.boardfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text3')}}：￥{{calcPcbPriceData.filmfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text4')}}：￥{{calcPcbPriceData.panelfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text5')}}：￥{{calcPcbPriceData.colorfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text6')}}：￥{{calcPcbPriceData.sprayfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text7')}}：￥{{calcPcbPriceData.testfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text8')}}：￥{{calcPcbPriceData.urgentfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text9')}}：￥{{calcPcbPriceData.taxationfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text10')}}：￥{{calcPcbPriceData.specfee || "0.00"}}</div>
					<div class="ri_div_text3">
						{{$t('valuation.quotation.text11')}}：￥{{calcPcbPriceData.discountfee || "0.00"}}</div>
					<div class="ri_div_text4">
						{{$t('valuation.quotation.text12')}}：￥{{calcPcbPriceData.otherprice || "0.00"}}</div>
		
					<div class="ri_div_text5">
						{{$t('valuation.quotation.text13')}}:
						<div class="price">
							￥{{calcPcbPriceData.orderno || "0.00"}}</div>
					</div>
					
					<div class="ri_div_buttonDiv">
						<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
							@click.native.prevent="dataInfo">
							<span v-if="!loading">{{$t('valuation.quotation.button')}}</span>
							<span v-else>{{$t('valuation.quotation.button')}}...</span>
						</el-button>
					</div>
					
					<!-- <div class="ri_div_buttonDiv">
						<el-button :loading="addLoading" size="medium" type="primary" style="width:100%;"
							@click.native.prevent="addShoppingCart">
							<span v-if="!addLoading">加入购物车</span>
							<span v-else>加入购物车中...</span>
						</el-button>
					</div> -->
					
					<!-- <div class="ri_div_buttonDiv">
						<div class="ri_div_bd_text"
							@click="dataInfo">{{$t('valuation.quotation.button')}}</div>
					</div> -->
				</div>
			</div>
		</div>
		
		<div class="selectForm2" v-if="windowWidth<=1199">
			<div class="sf_div">
				<div class="sf_div_title">
					<div class="sf_div_ti_title">{{$t('valuation.pcb.title1')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text1')}}</div>
				</div>
				<board-element :dataList="dataList"></board-element>
				<board-element-two :dataList="dataList"></board-element-two>
				<div class="sf_div_title" style="background: #f8f8f8;">
					<div class="sf_div_ti_title" style="padding-top: 5px;">{{$t('valuation.pcb.title2')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text2')}}</div>
				</div>
				<individualization :dataList="dataList"></individualization>
		
				<div class="sf_div_title" style="background: #f8f8f8;">
					<div class="sf_div_ti_title">{{$t('valuation.pcb.title3')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text3')}}</div>
					<div class="sf_div_ti_text2">{{$t('valuation.pcb.text4')}}</div>
				</div>
				<special-processes :dataList="dataList"></special-processes>
		
				<div class="sf_div_title" style="background: #f8f8f8;">
					<div class="sf_div_ti_title">{{$t('valuation.pcb.title4')}}</div>
					<div class="sf_div_ti_text">{{$t('valuation.pcb.text5')}}</div>
				</div>
				<order-information :dataList="dataList"></order-information>
			</div>
			
			<div style="background: #fff;box-shadow: 0 15px 30px  rgba(0, 0, 0, .4);padding: 0 20px;position: fixed;bottom: 0;width: 100%;left: 0;display: flex;align-items: center;height: 60px;">
				<div style="padding-top: 10px;font-size: 10px;padding-bottom: 10px;display: flex;line-height: 21px;">
					{{$t('valuation.quotation.text13')}}:
					<div style="font-size: 16px;color: red;font-weight: bold;padding-left: 0.5vw;">
						￥{{calcPcbPriceData.orderno || "0.00"}}</div>
						
					<div style="padding-left: 20px;color: #409EFF;" @click="drawerClick">{{$i18n.locale=="en" ? 'Quotation Details' : '报价明细'}}</div>
				</div>
				
				<div style="position: absolute;right: 0;background: #d1102d;padding: 0 40px;bottom: 0;color: #fff;height: 60px;display: flex;align-items: center;" @click="dataInfo">{{$t('valuation.quotation.button')}}</div>
			</div>
			
			<el-drawer
			  :title="$t('valuation.quotation.title')"
			  :visible.sync="drawer"
			  :size="'60%'"
			  :direction="direction"
			  
			  :before-close="handleClose">
			  <div class="ri_div2">
			  	<!-- <div style="padding-top: 0.5vw;font-size: 0.5vw;">快递费：￥0.00</div> -->
			  	<div class="ri_div_text2">
			  		{{$t('valuation.quotation.text1')}}：￥{{calcPcbPriceData.engineerfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text2')}}：￥{{calcPcbPriceData.boardfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text3')}}：￥{{calcPcbPriceData.filmfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text4')}}：￥{{calcPcbPriceData.panelfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text5')}}：￥{{calcPcbPriceData.colorfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text6')}}：￥{{calcPcbPriceData.sprayfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text7')}}：￥{{calcPcbPriceData.testfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text8')}}：￥{{calcPcbPriceData.urgentfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text9')}}：￥{{calcPcbPriceData.taxationfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text10')}}：￥{{calcPcbPriceData.specfee || "0.00"}}</div>
			  	<div class="ri_div_text3">
			  		{{$t('valuation.quotation.text11')}}：￥{{calcPcbPriceData.discountfee || "0.00"}}</div>
			  	<div class="ri_div_text4">
			  		{{$t('valuation.quotation.text12')}}：￥{{calcPcbPriceData.otherprice || "0.00"}}</div>
			  		
			  	<div class="ri_div_text5">
			  		{{$t('valuation.quotation.text13')}}:
			  		<div class="price">
			  			￥{{calcPcbPriceData.orderno || "0.00"}}</div>
			  	</div>
			  </div>
			</el-drawer>
		</div>
		
		<login-model :open="loginOpen" @ok="submitForm" @close="close"></login-model>
	</div>
</template>

<script>
	import {
		dataInJs
	} from './data.js';
	import boardElement from './selectForm/boardElement.vue'
	import boardElementTwo from './selectForm/boardElementTwo.vue'
	import individualization from './selectForm/individualization.vue'
	import specialProcesses from './selectForm/specialProcesses.vue'
	import orderInformation from './selectForm/orderInformation.vue'
	import loginModel from './loginModel.vue'
	import {
		getMyAddList
	} from '@/api/userInfo/index'
	import Cookies from "js-cookie";
	import {
		calcPcbPriceReturn
	} from './saveOrder.js'
	import {
		saveOrderReturn
	} from './submit.js'
	import {
		addShoppingCartReturn
	} from './addShoppingCart.js'
	import {
		Notification,
		MessageBox,
		Message,
		Loading
	} from 'element-ui'
	import {
		saveOrder,
		savePcb,
		clientLand
	} from '../../../api/home/index.js'
	export default {
		components: {
			boardElement,
			boardElementTwo,
			individualization,
			specialProcesses,
			orderInformation,
			loginModel
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				dataList: null, //list数据
				shippingMethod: 0, //出货方式
				calcPcbPriceData: null, //获取计价数据
				userInfo: null,
				drawer: false,
				direction: 'rtl',
				loginOpen:false,
				loading: false,
				addLoading:false,
			}
		},

		created() {
			calcPcbPriceReturn()
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.calcPcbPriceData = this.$store.state.selectForm.calcPcbPriceData
			this.dataList = this.$store.state.selectForm.dataInfoContent
			// console.log(this.dataList)
		},

		mounted() {

		},

		watch: {
			"$store.state.selectForm.calcPcbPriceData"(val) {
				this.calcPcbPriceData = val
			}
		},

		computed: {
			
		},

		methods: {
			dataInfo: function() {
				this.loading=true;
				if(this.userInfo!==undefined){
					let queryParam ={
						pageNum: 1,
						pageSize: 50,
						uid: this.userInfo.id,
						isdelete:0
					}
					getMyAddList(queryParam).then(res=>{
						if(res.data.content.length>0){
							saveOrderReturn(1,this.calcPcbPriceData)
							this.loading=false;
						}else{
							this.$message.error('请新增订单地址~')
							this.loading=false;
						}
					})
				}else{
					this.loginOpen=true;
				}
			},
			
			submitForm:function(e){
				clientLand(e).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						Cookies.set("userInfo2", JSON.stringify(res.data), {
							expires: 30
						});
						Cookies.set("role2", res.role, {
							expires: 30
						});
						this.userInfo = Cookies.get("userInfo2")
						if(this.userInfo){
							this.userInfo = JSON.parse(this.userInfo)
						}
						this.dataInfo();
						location.reload();
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			addShoppingCart:function(){
				this.addLoading=true;
				if(this.userInfo!==undefined){
					addShoppingCartReturn(1,this.calcPcbPriceData)
					this.addLoading=false;
				}else{
					this.addLoading=false;
				}
			},
			
			drawerClick:function(){
				this.drawer=true
			},
			
			handleClose(done) {
			    this.drawer=false
			},
			
			close:function(){
				this.loginOpen=false;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.selectForm{
			display: flex;
			width: 100%;
			padding: 0 200px;
			
			.sf_div{
				width: 70%;
				border: 1px solid #e8eaeb;
				margin-bottom: 20px;
				
				.sf_div_title{
					padding: 1vw;
					align-items: center;
					
					.sf_div_ti_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sf_div_ti_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
					
					.sf_div_ti_text2{
						color: red;
						font-size: 0.8vw;
						padding-top: 5px;
					}
				}
			}
		}
		
		.right{
			width: 30%;
			
			.ri_div{
				padding: 20px;
				border: 1px solid #e8eaeb;
				margin-left: 20px;
				
				.ri_div_text1{
					font-weight: bold;
					font-size: 16px;
				}
				
				.ri_div_text2{
					padding-top: 1vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text3{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text4{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					border-bottom: 1px solid #e8eaeb;
				}
				
				.ri_div_text5{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					display: flex;
					line-height: 2vw;
					
					.price{
						font-size: 16px;
						color: red;
						font-weight: bold;
						padding-left: 0.5vw;
					}
				}
				
				.ri_div_buttonDiv{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.ri_div_bd_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 12px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.selectForm{
			display: flex;
			width: 100%;
			padding: 0 200px;
			
			.sf_div{
				width: 70%;
				border: 1px solid #e8eaeb;
				margin-bottom: 20px;
				
				.sf_div_title{
					padding: 1vw;
					align-items: center;
					
					.sf_div_ti_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sf_div_ti_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
					
					.sf_div_ti_text2{
						color: red;
						font-size: 0.8vw;
						padding-top: 5px;
					}
				}
			}
		}
		
		.right{
			width: 30%;
			
			.ri_div{
				padding: 20px;
				border: 1px solid #e8eaeb;
				margin-left: 20px;
				
				.ri_div_text1{
					font-weight: bold;
					font-size: 16px;
				}
				
				.ri_div_text2{
					padding-top: 1vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text3{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text4{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					border-bottom: 1px solid #e8eaeb;
				}
				
				.ri_div_text5{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					display: flex;
					line-height: 2vw;
					
					.price{
						font-size: 20px;
						color: red;
						font-weight: bold;
						padding-left: 0.5vw;
					}
				}
				
				.ri_div_buttonDiv{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.ri_div_bd_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 12px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.selectForm{
			display: flex;
			width: 100%;
			padding: 0 140px;
			
			.sf_div{
				width: 70%;
				border: 1px solid #e8eaeb;
				margin-bottom: 20px;
				
				.sf_div_title{
					padding: 1vw;
					align-items: center;
					
					.sf_div_ti_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sf_div_ti_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
					
					.sf_div_ti_text2{
						color: red;
						font-size: 0.8vw;
						padding-top: 5px;
					}
				}
			}
		}
		
		.right{
			width: 30%;
			
			.ri_div{
				padding: 20px;
				border: 1px solid #e8eaeb;
				margin-left: 20px;
				
				.ri_div_text1{
					font-weight: bold;
					font-size: 16px;
				}
				
				.ri_div_text2{
					padding-top: 1vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text3{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text4{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					border-bottom: 1px solid #e8eaeb;
				}
				
				.ri_div_text5{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					display: flex;
					line-height: 2vw;
					
					.price{
						font-size: 2vw;
						color: red;
						font-weight: bold;
						padding-left: 0.5vw;
					}
				}
				
				.ri_div_buttonDiv{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.ri_div_bd_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 12px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.selectForm{
			display: flex;
			width: 100%;
			padding: 0 30px;
			
			.sf_div{
				width: 70%;
				border: 1px solid #e8eaeb;
				margin-bottom: 20px;
				
				.sf_div_title{
					padding: 1vw;
					align-items: center;
					
					.sf_div_ti_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sf_div_ti_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
					
					.sf_div_ti_text2{
						color: red;
						font-size: 0.8vw;
						padding-top: 5px;
					}
				}
			}
		}
		
		.right{
			width: 30%;
			
			.ri_div{
				padding: 20px;
				border: 1px solid #e8eaeb;
				margin-left: 20px;
				
				.ri_div_text1{
					font-weight: bold;
					font-size: 16px;
				}
				
				.ri_div_text2{
					padding-top: 1vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text3{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
				}
				
				.ri_div_text4{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					border-bottom: 1px solid #e8eaeb;
				}
				
				.ri_div_text5{
					padding-top: 0.5vw;
					font-size: 12px;
					padding-bottom: 0.5vw;
					display: flex;
					line-height: 2vw;
					
					.price{
						font-size: 2vw;
						color: red;
						font-weight: bold;
						padding-left: 0.5vw;
					}
				}
				
				.ri_div_buttonDiv{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.ri_div_bd_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 12px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.selectForm2{
			display: flex;
			width: 100%;
			padding: 0 30px 100px 30px;
			
			.sf_div{
				width: 100%;
				border: 1px solid #e8eaeb;
				margin-bottom: 20px;
				
				.sf_div_title{
					padding: 1vw;
					align-items: center;
					
					.sf_div_ti_title{
						font-size: 1vw;
						font-weight: bold;
					}
					
					.sf_div_ti_text{
						font-size: 0.8vw;
						color: #888;
						padding-top: 5px;
					}
					
					.sf_div_ti_text2{
						color: red;
						font-size: 0.8vw;
						padding-top: 5px;
					}
				}
			}
		}
		
		.ri_div2{
			padding: 20px;
			margin-left: 20px;
			
			.ri_div_text1{
				font-weight: bold;
				font-size: 1vw;
			}
			
			.ri_div_text2{
				padding-top: 1vw;
				font-size: 0.5vw;
				padding-bottom: 0.5vw;
			}
			
			.ri_div_text3{
				padding-top: 0.5vw;
				font-size: 0.5vw;
				padding-bottom: 0.5vw;
			}
			
			.ri_div_text4{
				padding-top: 0.5vw;
				font-size: 0.5vw;
				padding-bottom: 0.5vw;
			}
			
			.ri_div_text5{
				padding-top: 0.5vw;
				font-size: 0.5vw;
				padding-bottom: 0.5vw;
				display: flex;
				line-height: 2vw;
				
				.price{
					font-size: 2vw;
					color: red;
					font-weight: bold;
					padding-left: 0.5vw;
				}
			}
			
			.ri_div_buttonDiv{
				position: relative;
				padding-top:20px;
				text-align: right;
				z-index: 1;
				
				.ri_div_bd_text{
					padding: 10px 40px;
					cursor: pointer;
					display: inline-block;
					font-size: 16px;
					border: 1px solid #d1102d;
					background: #d1102d;
					color: #fff;
					box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
					border-radius: 100px;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.selectForm2{
			display: flex;
			width: 100%;
			padding: 0 30px 100px 30px;
			
			.sf_div{
				width: 100%;
				border: 1px solid #e8eaeb;
				margin-bottom: 20px;
				
				.sf_div_title{
					padding: 1vw;
					align-items: center;
					
					.sf_div_ti_title{
						font-size: 16px;
						font-weight: bold;
					}
					
					.sf_div_ti_text{
						font-size: 12px;
						color: #888;
						padding-top: 5px;
					}
					
					.sf_div_ti_text2{
						color: red;
						font-size: 12px;
						padding-top: 5px;
					}
				}
			}
		}
		
		.ri_div2{
			padding: 20px;
			margin-left: 20px;
			
			.ri_div_text1{
				font-weight: bold;
				font-size: 12px;
			}
			
			.ri_div_text2{
				padding-top: 10px;
				font-size: 12px;
				padding-bottom: 10px;
			}
			
			.ri_div_text3{
				padding-top: 10px;
				font-size: 12px;
				padding-bottom: 10px;
			}
			
			.ri_div_text4{
				padding-top: 10px;
				font-size: 12px;
				padding-bottom: 10px;
			}
			
			.ri_div_text5{
				padding-top: 10px;
				font-size: 12px;
				padding-bottom: 10px;
				display: flex;
				line-height: 21px;
				
				.price{
					font-size: 21px;
					color: red;
					font-weight: bold;
					padding-left: 10px;
				}
			}
			
			.ri_div_buttonDiv{
				position: relative;
				padding-top:20px;
				text-align: right;
				z-index: 1;
				
				.ri_div_bd_text{
					padding: 10px 40px;
					cursor: pointer;
					display: inline-block;
					font-size: 16px;
					border: 1px solid #d1102d;
					background: #d1102d;
					color: #fff;
					box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
					border-radius: 100px;
				}
			}
		}
	}
</style>
