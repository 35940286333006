import { calcPcbComponentlnfoPrice } from '@/api/home/index.js' 
import store from '@/store'
import Cookies from "js-cookie";

export function calcSmtReturn() {
	// var userInfo = JSON.parse(Cookies.get("userInfo2")) || null
	// console.log("userInfo",userInfo)
	if(store.state.selectForm.componentsNumber !== '' && store.state.selectForm.circuitBoardsNumber !== ''){
		let data = {
			smtcompcount:Number(store.state.selectForm.componentsNumber),
			smtboardcount:Number(store.state.selectForm.circuitBoardsNumber),
			smttechreq:store.state.selectForm.processRequirements,
			smtissten:store.state.selectForm.steelMeshTypeIndex,
			smtagentcomp:store.state.selectForm.purchaseBehalfIndex,
			smtislac:store.state.selectForm.conformalCoatingIndex,
			smtisbake:store.state.selectForm.componentBakingIndex,
			smtbakedate:store.state.selectForm.bakingTimeIndex,
			smtsenddate:store.state.selectForm.deliveryTime,
			smtmark:store.state.selectForm.smtDemand,
			// pcbcount:1
		}
		store.dispatch("setSmtOrderInfo", {
			smtOrderInfo: data,
		});
		calcPcbComponentlnfoPrice(data).then(res=>{
			// console.log("calcPcbComponentlnfoPrice",res)
			store.dispatch("setSmtFee", {
			    smtfee: res.data,
			});
		})
	}else{
		store.dispatch("setSmtOrderInfo", {
			smtOrderInfo: null,
		});
	}
}