import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
export default {
	components: {
		AntModal,
		CButton
	},
	props: {
		open: { // 按钮默认文本
			type: Boolean,
			default: false
		},
		defaultPlainOptions: {
			type: Array,
			default: () => []
		},
		dataList: {
			type: Array,
			default: () => []
		},
		bomForm: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			modalOpen: this.open, //弹窗打开关闭
			formTitle: "选择BOM", //弹框名
			checkedList: [],
			indeterminate: false,
			checkAll: false,
			plainOptions: ['排序号','完整型号','品牌','封装','参数','数量','含税单价','年份','备注'],
			data: [],//重组成已选择的表头对象列表
			dataColumnsList:[],//重组成已选择的表头列表
			columns: [],
			gaugeOutfit: [], //表单选择的表头列表
			objList:null,//重组完成的数据
			form: {
				bomtype: undefined,
				bombrand: undefined,
				bompack: undefined,
				bomparam: undefined,
				bomamount: undefined,
				bomtaxprice: undefined,
				bomyear: undefined,
				bomnotes: undefined
			},
			rules: {},
			
			// 浏览器窗口宽度高度
			windowWidth: 0,
			windowHeight: 0,
		}
	},

	created() {
		let that = this;
		that.windowWidth = document.body.clientWidth;
		that.windowHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.windowWidth = document.body.clientWidth;
				this.windowHeight = document.body.clientHeight;
			})();
		};
	},

	mounted() {

	},

	watch: {
		open(val){
			// console.log("dataList",JSON.parse(sessionStorage.getItem('vuex')))
			var arr = JSON.parse(sessionStorage.getItem('vuex'))
			this.columns=[]
			if(this.$store.state.selectForm.bomForm!==null){
				this.columns=arr.selectForm.bomForm
				// console.log("columns",this.columns)
				this.onDataInfo(val)
			}else{
				this.plainOptions = ['排序号','完整型号','品牌','封装','参数','数量','含税单价','年份','备注']
				this.headerInfo()
			}
		},
		// defaultPlainOptions(val) {
		// 	this.plainOptions = val
		// },
		// dataList(val) {
		// 	this.data = val
		// },
	},

	computed: {

	},

	methods: {

		//------------------------取消按钮-------------------------------
		cancel: function() {
			console.log("cancel")
			this.$confirm('关闭弹窗将不会保存当前操作，是否关闭？', '是否关闭弹窗', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
			  // console.log("cancel",this.$store.state.selectForm.bomForm)
			  this.modalOpen = false;
			  // this.$store.dispatch("setXlsxDataList", {
			  //     xlsxDataList: this.columns,
			  // });
			  this.$emit('close');
			}).catch(() => {});
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			var numReg = /^\d+(\.\d+)?$/
			var bomtaxprice = []
			// console.log(this.objList)
			if(this.objList!==null){
				this.objList.map(res=>{
					if(res.bomtaxprice!==undefined){
						// console.log("1",numReg.test(res.bomtaxprice))
						if(numReg.test(res.bomtaxprice)==false){
							bomtaxprice.push(false)
						}
					}else{
						// console.log("2",numReg.test(res.bomtaxprice))
						bomtaxprice.push(true)
					}
				})
				// console.log("bomtaxprice",bomtaxprice)
				var bomamount = []
				this.objList.map(res=>{
					if(res.bomamount!==undefined){
						if(numReg.test(res.bomamount)==false){
							bomamount.push(false)
						}else{
							bomamount.push(true)
						}
					}else{
						bomamount.push(true)
					}
				})
			}
			// console.log(bomtaxprice.indexOf(false))
			// console.log(bomamount.indexOf(false))
			if(this.dataColumnsList.indexOf("完整型号")==-1){
				// console.log("1")
				this.$message.error('请先选择bom表单中的完整型号列名~')
				return
			}else if(this.dataColumnsList.indexOf("数量")==-1){
				// console.log("3")
				this.$message.error('请先选择bom表单中的数量列名~')
				return
			} else if(bomtaxprice.indexOf(false)!==-1){
				this.$message.error('含税单价只能为数字或者小数~')
				return
			} else if(bomamount.indexOf(false)!==-1){
				// console.log("5")
				this.$message.error('数量只能为数字或者小数~')
				return
			} else {
				// console.log("form",this.form)
				var a = []
				this.objList.map(res2=>{
					if(JSON.stringify(res2)!=='{}'){
						a.push(res2)
					}
				})
				this.objList=a
				console.log("objList",this.objList)
				this.$emit('ok', this.columns, this.objList, this.form)
			}
			// this.objList.map(res=>{
			// 	if(!numRe.test(res.bomamount)){
			// 		this.$message({
			// 		    type: 'error',
			// 		    message: '数量只能是数字或者小数',
			// 		})
			// 		return
			// 	}
			// })
			
		},

		//---------------------dataObj----------------------------------
		dataObj: function(e) {
			var list = []
			var i = 0
			// console.log("dataList",e)
			this.dataList.map(dataList => {
				let data = {}
				i++
				e.map(res => {
					if (res.value == "排序号"){
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomnum'] = i
						}
					}
					// if (res.value == "序号") {
					// 	data['id'] = dataList[res.dataIndex]
					// }
					
					if (res.value == "完整型号") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomtype'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "品牌") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bombrand'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "封装") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bompack'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "参数") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomparam'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "数量") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomamount'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "含税单价") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomtaxprice'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "年份") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomyear'] = dataList[res.dataIndex]
						}
					}
					
					if (res.value == "备注") {
						if(dataList[res.dataIndex]!==undefined && dataList[res.dataIndex]!==' ' && dataList[res.dataIndex]!==''){
							data['bomnotes'] = dataList[res.dataIndex]
						}
					}
				})
				list.push(data)
			})
			return list
		},
		
		//------------------表格头重组------------------------
		headerInfo:function(){
			this.columns=[];
			let i=0
			this.defaultPlainOptions.map(res=>{
				i++
				let data={
					title: res,
					dataIndex: res,
					key:i,
					width: 150,
					value:undefined,
					scopedSlots: {
						filterDropdown: "filterDropdown"+i,
					}
				}
				this.columns.push(data)
			})
			// console.log(this.columns)
		},

		//---------------------完整型号选择列-------------------
		onDataInfo: function() {
			// console.log(this.columns)
			//重组成已选择的表头对象列表
			this.data=[]
			let arr = ['排序号','完整型号','品牌','封装','参数','数量','含税单价','年份','备注']
			//重组成已选择的表头列表
			this.dataColumnsList=[]
			this.columns.map(res=>{
				if(res.value){
					this.data.push(res)
				}
			})
			this.data.map(res=>{
				if(res.value=="排序号"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="排序号"
					})
					
				}else if(res.value=="完整型号"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="完整型号"
					})
					
				}else if(res.value=="品牌"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="品牌"
					})
					
				}else if(res.value=="封装"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="封装"
					})
					
				}else if(res.value=="参数"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="参数"
					})
					
				}else if(res.value=="数量"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="数量"
					})
					
				}else if(res.value=="含税单价"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="含税单价"
					})
					
				}else if(res.value=="年份"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="年份"
					})
				}else if(res.value=="备注"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="备注"
					})
				}
			})
			this.plainOptions=arr
			this.objList=this.dataObj(this.data)
		},
		
		//--------------选择列--------------------
		filterDropdownClick(item,index,confirm){
			this.columns[index].value=item
			this.columns[index].title=item
			// console.log("重组成已选择的表头对象列表",this.columns)
			// this.$store.dispatch("setBomForm", {
			//     bomForm: this.columns,
			// });
			//重组成已选择的表头对象列表
			this.data=[]
			let arr = ['排序号','完整型号','品牌','封装','参数','数量','含税单价','年份','备注']
			//重组成已选择的表头列表
			this.dataColumnsList=[]
			this.columns.map(res=>{
				if(res.value){
					this.data.push(res)
				}
			})
			this.data.map(res=>{
				if(res.value=="排序号"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="排序号"
					})
					
				}else if(res.value=="完整型号"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="完整型号"
					})
					
				}else if(res.value=="品牌"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="品牌"
					})
					
				}else if(res.value=="封装"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="封装"
					})
					
				}else if(res.value=="参数"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="参数"
					})
					
				}else if(res.value=="数量"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="数量"
					})
					
				}else if(res.value=="含税单价"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="含税单价"
					})
					
				}else if(res.value=="年份"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="年份"
					})
				}else if(res.value=="备注"){
					
					this.form.bomtype=res.title
					this.dataColumnsList.push(res.value)
					arr = arr.filter(res=>{
						return res!=="备注"
					})
				}
			})
			this.plainOptions=arr
			this.objList=this.dataObj(this.data)
			// console.log("arr",this.data)
			// console.log(this.columns)
			// console.log("columns",dataList)	
			confirm();
		},

		//---------------------完整型号选择列-------------------
		// onChange: function(event, item, confirm) {
		// 	// console.log(this.columns)
		// 	//重组成已选择的表头对象列表
		// 	this.data=[]
		// 	let arr = ['完整型号','品牌','封装','参数','数量','含税单价','年份','备注']
		// 	//重组成已选择的表头列表
		// 	this.dataColumnsList=[]
		// 	this.columns.map(res=>{
		// 		if(res.value){
		// 			this.data.push(res)
		// 		}
		// 	})
		// 	this.data.map(res=>{
		// 		if(res.value=="排序号"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="排序号"
		// 			})
					
		// 		}else if(res.value=="完整型号"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="完整型号"
		// 			})
					
		// 		}else if(res.value=="品牌"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="品牌"
		// 			})
					
		// 		}else if(res.value=="封装"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="封装"
		// 			})
					
		// 		}else if(res.value=="参数"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="参数"
		// 			})
					
		// 		}else if(res.value=="数量"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="数量"
		// 			})
					
		// 		}else if(res.value=="含税单价"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="含税单价"
		// 			})
					
		// 		}else if(res.value=="年份"){
					
		// 			this.form.bomtype=res.title
		// 			this.dataColumnsList.push(res.value)
		// 			arr = arr.filter(res=>{
		// 				return res!=="年份"
		// 			})
		// 		}
		// 	})
		// 	this.plainOptions=arr
		// 	this.objList=this.dataObj(this.data)
		// 	// console.log("arr",this.data)
		// 	// console.log(this.columns)
		// 	// console.log("columns",dataList)	
		// 	confirm();
		// },
	}
}