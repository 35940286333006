<template>
	<div style="padding: 1vw;">
		<!-- <div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">包装要求：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					content="中性包装是指空白盒子包装、不放送货单和收据，标签及快递单均不显示JDB任何标识">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 80%;">
				<div style="display: flex;">
					<single-choice :dataIndex="packagingRequirements" :dataList="dataList.packagingRequirements" @select="selectPackagingRequirementsClick">
					</single-choice>
				</div>
			</div>
		</div> -->

		<!-- <div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">添加订单编号：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover" content="1、添加订单编号 ,订单编号由8-10位字母和数字组成，是识别此订单的唯一编号，捷多邦添加订单编号规范：
a.客户有特别要求位置时一定要按照客户要求的位置添
b.加编号时尽量避开客户本身板上的机种编号、标识等一些标记
c.加编号的优先层顺序：字符层->阻焊层->线路层，先优先顶层再为底层
d.位置优先顺序:工艺边->元件框内->板子边缘
e.加在阻焊层的编号尽量加在基材上不要加到铜面上
f.字符层上的编号尽量添加在贴件字符框内，方便客户贴件后不会显示我司编号，给客户照成不必要的麻烦。如无贴件字符框则尽量加在板边'
2、在指定位置添加订单编号（所有PCB订单，默认全部加印订单编号.如果您希望在指定位置加客编，则必须在电路板上要有指定的字符JDBJDBJDB，字宽以6mil为宜。字符高不小于0.8MM。捷多邦添加订单编号时直接替换，如没有找到本字符，则不进行指定位置添加！此选项不生效。
3、不添加订单编号将不在PCB上印订单编号">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 80%;">
				<div style="display: flex;">
					<single-choice :dataIndex="addOrderNumber" :dataList="dataList.addOrderNumber" @select="selectAddOrderNumberClick">
					</single-choice>
				</div>
			</div>
		</div> -->
		
		<!-- <div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">添加或更新生产日期：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					content="格式为年月日 如：2019 03 15 （勾选了需要选项，将在每一个单只内加上 生产日期，这个日期以后每一批次返单会自动变更为返单确认订单当天的日期，方便客户追溯）">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 80%;">
				<div style="display: flex;">
					<single-choice :dataIndex="dateManufacture" :dataList="dataList.dateManufacture" @select="selectDateManufactureClick">
					</single-choice>
				</div>
			</div>
		</div> -->
		
		<!-- <div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">UL标识：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					content="格物思已通过UL认证，勾选了需要选项，格物思将在顶层字符的合适的位置添加UL标识">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 80%;">
				<div style="display: flex;">
					<single-choice :dataIndex="ULidentification" :dataList="dataList.ULidentification" @select="selectULidentificationClick">
					</single-choice>
				</div>
			</div>
		</div> -->
		
		<div style="display: flex;padding-top: 20px;">
			<div class="be_title">
				<div class="be_mandatory">*</div>
				<div class="be_text">{{$t('valuation.individualization.title1')}}：</div>
				<el-popover placement="bottom-start" width="400" trigger="hover"
					:content="$t('valuation.individualization.content1')">
					<div slot="reference">
						<i class="el-icon-question"></i>
					</div>
				</el-popover>
			</div>
			<div style="width: 80%;">
				<div style="display: flex;">
					<single-choice :dataIndex="productionDraft" :dataList="dataList[13]" @select="selectProductionDraftClick">
					</single-choice>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import singleChoice from '@/components/singleChoice/index.vue';
	import singleChoiceImage from '@/components/singleChoiceImage/index.vue';
	import singleChoiceColor from '@/components/singleChoiceColor/index.vue';
	import singleChoicePopup from '@/components/singleChoicePopup/index.vue';
	import { calcPcbPriceReturn } from '../saveOrder.js'
	export default {
		components: {
			singleChoice,
			singleChoiceImage,
			singleChoiceColor,
			singleChoicePopup
		},
		props: {
			dataList: {
				type: Array,
				default: () => []
			},
		},
		data() {
			return {
				packagingRequirements:0,//包装要求下标
				addOrderNumber:0,//增加订单编号下标
				dateManufacture:0,//增加或者更新生产日期下标
				ULidentification:0,//ul标识
				productionDraft:0,//确认生产稿下标
			}
		},

		created() {
			this.packagingRequirements = this.$store.state.selectForm.packagingRequirements - 1;
			this.addOrderNumber = this.$store.state.selectForm.addOrderNumber - 1;
			this.dateManufacture = this.$store.state.selectForm.dateManufacture - 1;
			this.ULidentification = this.$store.state.selectForm.ULidentification - 1;
			this.productionDraft = this.$store.state.selectForm.productionDraft - 1; 
		},

		mounted() {

		},

		watch: {

		},

		computed: {
			
		},

		methods: {
			//----------------------包装需求单选回调---------------------------
			selectPackagingRequirementsClick:function(e){
				this.packagingRequirements = e;
				this.$store.dispatch("setPackagingRequirements", {
				    packagingRequirements: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//---------------------增加订单编号单选回调------------------------
			selectAddOrderNumberClick:function(e){
				this.addOrderNumber = e;
				this.$store.dispatch("setAddOrderNumber", {
				    addOrderNumber: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//----------------------增加或更新生产日期单选回调----------------------------
			selectDateManufactureClick:function(e){
				this.dateManufacture = e;
				this.$store.dispatch("setDateManufacture", {
				    dateManufacture: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//----------------------UL标识------------------------------
			selectULidentificationClick:function(e){
				this.ULidentification = e;
				this.$store.dispatch("setULidentification", {
				    ULidentification: e + 1,
				});
				calcPcbPriceReturn()
			},
			
			//------------------确认生产稿单选回调---------------------
			selectProductionDraftClick:function(e){
				this.productionDraft = e;
				this.$store.dispatch("setProductionDraft", {
				    productionDraft: e + 1,
				});
				calcPcbPriceReturn()
			}
		}
	}
</script>

<style lang="less">
	input[type=text]:focus {
		outline: none;

	}
	
	@media only screen and (min-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 20%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 8vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 20%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 8vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 20%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 8vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 20%;
		
			.be_mandatory {
				font-size: 1vw;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 0.8vw;
			}
		}
		
		.input1 {
			width: 8vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 15%;
		
			.be_mandatory {
				font-size: 12px;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1 {
			width: 8vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
	
	@media only screen and (max-width:767px) {
		.el-icon-question {
			color: #888;
		}
		
		.el-icon-question:hover {
			color: red;
		}
		
		.be_title {
			display: flex;
			// align-items: center;
			width: 30%;
		
			.be_mandatory {
				font-size: 12px;
				color: red;
			}
		
			.be_text {
				color: #888;
				font-size: 10px;
			}
		}
		
		.input1 {
			width: 8vw;
			height: 2vw;
			line-height: 2vw;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
	}
</style>
