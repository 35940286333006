import { calcPcbPrice } from '@/api/home/index.js'
import store from '@/store'
import router from '@/router'
 import { Message } from 'element-ui'
 import Cookies from "js-cookie";


export function calcPcbPriceReturn() {
	// console.log("|calcPcbPriceReturn",store.state.selectForm.designDocuments)
	// var userInfo = JSON.parse(Cookies.get("userInfo2")) || null
	if(store.state.selectForm.boardLength !== '' && store.state.selectForm.boardWidth !== '' && store.state.selectForm.boardNumber !== ''){
		let data={
			pcbcount:store.state.selectForm.boardNumber,
			pcbdesigncount:store.state.selectForm.designDocuments,
			pcbtype:store.state.selectForm.boardType,
			pcblayer:store.state.selectForm.boardNumberPliesIndex,
			pcbwidth:store.state.selectForm.boardWidth,
			pcblength:store.state.selectForm.boardLength,
			invoicetype: store.state.selectForm.invoiceType,
			invoiceinfo: store.state.selectForm.invoicingMethod,
			// heatfactor:1,
			// alstructure:1,
			orderattr: "1",
			pcbsellmodel: store.state.selectForm.shippingMethod,
			pcbbrand:store.state.selectForm.TGsub,
			pcbthick:store.state.selectForm.BoardThickness,
			pcbminlinewidth:store.state.selectForm.minimumLineWidth,
			pcbminhole:store.state.selectForm.minimumThroughHole,
			pcbholecolor:store.state.selectForm.resistanceWeldingColor,
			pcbfontcolor:store.state.selectForm.characterColor,
			pcbholespray:store.state.selectForm.solderPadSprayCoating,
			pcbweldcover:store.state.selectForm.resistanceWeldingCoverage,
			pcbcuthick:store.state.selectForm.copperThickness,
			pcbgoldthick:store.state.selectForm.goldthick,
			pcbspectech:store.state.selectForm.checkListData,
			pcbtestmethod:store.state.selectForm.TestMethodRadio
		}
		store.dispatch("setPcbInfo", {
			pcbInfo: data,
		});
		calcPcbPrice(data).then(res=>{
			// console.log("calcPcbPrice",res)
			store.dispatch("setCalcPcbPriceData", {
			    calcPcbPriceData: res.data,
			});
		})
	}else{
		store.dispatch("setPcbInfo", {
			pcbInfo: null,
		});
	}
}
